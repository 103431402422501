export const loanReasonOptions = [
  {
    label: "Auto Purchase",
    value: "AUTO_PURCHASE"
  },
  {
    label: "Auto Repair",
    value: "AUTO_REPAIR"
  },
  {
    label: "Home Improvement",
    value: "HOME_IMPROVEMENT"
  },
  {
    label: "Debt Consolidation",
    value: "DEBT_CONSOLIDATION"
  },
  {
    label: "Debt Settlement",
    value: "DEBT_SETTLEMENT"
  },
  {
    label: "Credit Card Consolidation",
    value: "CREDIT_CARD_CONSOLIDATION"
  },
  {
    label: "Small Business",
    value: "BUSINESS"
  },
  {
    label: "Rent or Mortgage",
    value: "RENT_OR_MORTGAGE"
  },
  {
    label: "Medical Expenses",
    value: "MEDICAL"
  },
  {
    label: "Moving",
    value: "MOVING"
  },
  {
    label: "Emergency Situation",
    value: "EMERGENCY_SITUATION"
  },
  {
    label: "Education",
    value: "EDUCATION"
  },
  {
    label: "Renewable Energy",
    value: "RENEWABLE_ENGERGY"
  },
  {
    label: "Student Loan Refinance",
    value: "STUDENT_LOAN_REFINANCE"
  },
  {
    label: "Taxes",
    value: "TAXES"
  },
  {
    label: "Other",
    value: "OTHER"
  }
];
