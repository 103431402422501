import React from "react";
import "./WhyChooseUS.scss";

const WhyChooseUS = () => {
  return (
    <div className="why-choose-us">
      <div className="container">
        <h2 className="title">Why choose US?</h2>

        <p>
          <b>Seekerloans.com</b> is a dedicated partner who works to earn your
          trust in connecting you with a reliable lender for financial
          solutions. Our online request is designed with the standard criteria
          to provide you with an easy and secure process while submitting your
          information. The network of lenders we are partnering with welcomes
          all credit types, which allows you to have more opportunities to get
          the loan.
        </p>
      </div>
    </div>
  );
};

export default WhyChooseUS;
