const isMobile = window.innerWidth < 768;

export const textInputStyles = {
  color: "#000",
  fontFamily: "Poppins",
  fontSize: isMobile ? "14px" : "15px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: isMobile && "1.9"
};

export const btnSubmitCustom = {
  width: "100%",
  height: isMobile ? "35px" : "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  borderRadius: "6px",
  background: "#F4C811",

  color: "#000",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: isMobile ? "16px" : "25px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  textTransform: "uppercase"
};
export const MenuProps = {
  PaperProps: {
    sx: {
      maxHeight: 228,
      borderRadius: "0 0 8px 8px",
      // SCROLL BAR
      "&::-webkit-scrollbar": {
        width: "0.4em",
        backgroundColor: "#B7B7B7"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#FFF",
        outline: "0.5px solid #000",
        borderRadius: "8px"
      },
      "& .MuiList-root": {
        padding: 0
      }
    }
  }
};

export const labelCustom = {
  margin: "0 0 3px",
  color: "#000",
  fontFamily: "Poppins",
  fontSize: isMobile ? "12px" : "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal"
};

export const selectCustom = {
  // SET HEIGHT FIELD
  minHeight: isMobile && "45px",

  position: "relative",
  backgroundColor: "#fff",

  "&.show": {
    position: "relative",
    "&.MuiOutlinedInput-root": {
      transition: "all 0.5s ease",
      borderRadius: "8px 8px 0 0"
    }
  },

  "&.MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "#B7B7B7"
    },
    "&:hover fieldset": {
      borderColor: "#B7B7B7"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#B7B7B7",
      borderWidth: "1px"
    }
  },
  ...textInputStyles
};

export const menuItemCustom = {
  padding: 0,
  minHeight: "38px !important",
  justifyContent: "center",
  borderBottom: "1px solid #ffffff14",

  backgroundColor: "#191C1A",

  color: "#fff",
  fontFamily: "Poppins",
  fontSize: isMobile ? "14px" : "15px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",

  "&.Mui-selected": {
    backgroundColor: "#33A8FC !important",
    color: "#fff"
  },
  "&.MuiMenuItem-root:hover": {
    backgroundColor: "#33A8FC !important",
    color: "#fff"
  }
};
