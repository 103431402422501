import React, { useEffect } from "react";

import "./RedirectPageUpgrade.scss";
import logo from "../../../assets/images/logo.png";
import upgrade from "../../../assets/images/upgrade.png";
import { Link } from "react-router-dom";

const RedirectPageUpgrade = () => {
  const windowWidth = window.innerWidth;

  const redirectURL = localStorage.getItem("redirectURL");
  useEffect(() => {
    setTimeout(() => {
      if (redirectURL) window.location.href = redirectURL;
    }, 2000);
  });

  return (
    <div className="redirect-upstart">
      <nav>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
      </nav>
      <div className="redirect-content">
        <h1>We've found the program for you!</h1>
        <h3 className="configuration">Congratulation!</h3>
        <h3 className="text">
          We are connecting you with a program who is a
          {windowWidth < 768 && <br />} perfect fit for you. Just a few
          {windowWidth > 768 && <br />} more steps to get
          {windowWidth < 768 && <br />} in touch with the lender!
        </h3>

        <div className="logo-upstart">
          <img src={upgrade} alt="" />
        </div>
        <div class="loading-text">
          <h3>
            REDIRECTING
            <span class="dot-one"> .</span>
            <span class="dot-two"> .</span>
            <span class="dot-three"> .</span>
          </h3>
        </div>
      </div>
      <footer>
        <p>© 2023 Seekerloans.com All rights reserved.</p>
      </footer>
    </div>
  );
};

export default RedirectPageUpgrade;
