export const options = [
  {
    value: "EMPLOYMENT",
    label: "Job income"
  },
  {
    value: "SELF_EMPLOYMENT",
    label: "Self employment"
  },
  {
    value: "MILITARY",
    label: "Military"
  },
  {
    value: "BENEFITS",
    label: "Benefits"
  }
];
