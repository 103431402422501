export const checkLastStep = (newContactRedux) => {
  const steps = [
    { variable: "loanReason", step: "step1" },
    { variable: "firstName", step: "step2" },
    { variable: "phone", step: "step3" },
    { variable: "bestTimeContact", step: "step4" },
    { variable: "zipCode", step: "step5" },
    { variable: "currentResidenceDuration", step: "step6" },
    { variable: "residenceOwnership", step: "step7" },
    { variable: "incomeSource", step: "step8" },
    { variable: "monthlyIncome", step: "step9" },
    { variable: "currentCompanyDuration", step: "step10" },
    { variable: "jobTitle", step: "step11" },
    { variable: "creditScoreRating", step: "step12" },
    { variable: "routingNumber", step: "step13" },
    { variable: "getDirectDeposit", step: "step14" },
    { variable: "currentBankDuration", step: "step15" },
    { variable: "licenseID", step: "step16" },
    { variable: "ownCar", step: "step17" },
    { variable: "socialSecurityNumber", step: "step18" },
    { variable: "unsecuredDebtAmount", step: "step19" }
  ];
  let lastStep = null;

  for (let i = steps.length - 1; i >= 0; i--) {
    const step = steps[i];
    if (newContactRedux.hasOwnProperty(step.variable)) {
      lastStep = step;
      break;
    }
  }
  return lastStep?.step;
};
