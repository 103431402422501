export const currentCompanyDurationOptions = [
  {
    value: "EXCELLENT",
    label: "Excellent (720+)"
  },
  {
    value: "GOOD",
    label: "Good (680-719)"
  },
  {
    value: "FAIR",
    label: "Fair (640-679)"
  },
  {
    value: "POOR",
    label: "Poor (639 or less)"
  },
  {
    value: "IDONTKNOW",
    label: "I don’t know"
  }
];
