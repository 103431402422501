import React, { useEffect, useState } from "react";
import "./ReadyToApply.scss";
import { NextBtn } from "../Button/NextBtn/NextBtn";
import { Link as Scroll } from "react-scroll";

import check from "../../assets/images/check.png";

const ReadyToApply = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const btnSubmitStyle = {
    margin: "auto",
    marginBottom: windowWidth > 756 ? "20px" : 0,
    width: windowWidth > 768 ? "534px" : "308px",
    height: windowWidth > 768 ? "69px" : "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    borderRadius: windowWidth > 768 ? "5px" : "2px",
    background: "#F4C811",

    color: "#000",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: windowWidth > 768 ? "25px" : "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "uppercase"
  };

  // Update windowWidth state on window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="ready-to-apply" data-aos="fade-down" data-aos-offset="-170">
      <div className="container">
        <div className="content">
          <div className="left">
            <h2 className="title">
              What are the {windowWidth > 768 && <br />}
              general criteria{windowWidth > 768 && <br />}
              to get the funds?
            </h2>
          </div>
          <div className="right">
            <div className="list-item">
              <div className="item">
                <div className="icon">
                  <img src={check} alt="" />
                </div>
                <p className="text">You must be 18 years of age or older.</p>
              </div>
              <div className="item">
                <div className="icon">
                  <img src={check} alt="" />
                </div>
                <p className="text">
                  You must have a valid checking or savings account with direct
                  deposit.
                </p>
              </div>
              <div className="item">
                <div className="icon">
                  <img src={check} alt="" />
                </div>
                <p className="text">
                  The monthly income starting from $1,500 is a great condition.
                </p>
              </div>
              <div className="item">
                <div className="icon">
                  <img src={check} alt="" />
                </div>
                <p className="text">
                  Not a military member serving the Air Force, Army, Coast
                  Guard, Marine Corps or Navy.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="group-btn">
          <Scroll
            className="menu-item"
            to="banner"
            spy={true}
            smooth={true}
            duration={800}
            offset={-200}
            style={{
              margin: "auto"
            }}
          >
            <NextBtn
              element="button"
              style={btnSubmitStyle}
              label="Get Started"
            />
          </Scroll>
        </div>
      </div>
    </div>
  );
};

export default ReadyToApply;
