import React, { useEffect } from "react";

import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import "./Home.scss";
import Banner from "../../components/Banner/Banner";
import HowItWork from "../../components/HowItWork/HowItWork";
import WhyChooseUS from "../../components/WhyChooseUS/WhyChooseUS";
import ReadyToApply from "../../components/ReadyToApply/ReadyToApply";
// import { FormTop } from "../../components/FormTop/FormTop";
import ScrollToTop from "react-scroll-to-top";

const Home = () => {
  useEffect(() => {
    (function() {
      var tf = document.createElement('script');
      tf.type = 'text/javascript'; 
      tf.async = true;
      tf.src = ("https:" == document.location.protocol ? 'https' : 'http') + "://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl&l=" + new Date().getTime() + Math.random();
      var s = document.getElementsByTagName('script')[0]; 
      s.parentNode.insertBefore(tf, s);
    })();
    
    // Tạo một Promise để giữ giá trị input
    function waitForTrustedFormCertUrl() {
      return new Promise((resolve) => {
        const intervalId = setInterval(() => {
          const trustedFormURL = document.querySelector('input[name="xxTrustedFormCertUrl"]')?.value;
          if (trustedFormURL) {
            clearInterval(intervalId); // Dừng kiểm tra khi giá trị có sẵn
            resolve(trustedFormURL);
          }
        }, 100); // Kiểm tra mỗi 100ms
      });
    }
    
    // Sử dụng Promise để đợi và lấy giá trị
    waitForTrustedFormCertUrl().then((trustedFormURL) => {
      console.log('trustedFormURL ', trustedFormURL);
      if (trustedFormURL) {
        sessionStorage.setItem('trustedFormURL', trustedFormURL);
      }
    });
    
    return () => {
      // Cleanup logic if needed
    };
    
  }, []);
  return (
    <div
      className="wrapper"
      style={{
        overflowX: "hidden"
      }}
    >
      <ScrollToTop smooth color="#33a8fc" />
      <Navbar />
      {/* <FormTop /> */}
      <Banner />
      <HowItWork style={{ zIndex: "-1" }} />
      <WhyChooseUS />
      <ReadyToApply />
      <Footer />
    </div>
  );
};

export default Home;
