import { updateDataBrevoMappingStep } from "../../utils/callApi";

import { useEffect, useState } from "react";

export const QuestionAnswer = ({
  questionsAnswerData,
  questionSelected,
  contactRedux,
  setQuestionSelected,
  setShowAndHideQuestion
}) => {
  const [listIds, setListIds] = useState([18]);
  const [answer, setAnswer] = useState("");

  const handleAnswer = (e) => {
    const value = e.target.dataset.value;
    setAnswer(value);
    const questionNumber = questionSelected?.questionNumber;

    if (value === "yes") {
      if (questionNumber === 1) setListIds((prev) => [...prev, 14]);
      else if (questionNumber === 2) setListIds((prev) => [...prev, 13]);
      else setListIds((prev) => [...prev, 12]);

      setQuestionSelected(questionsAnswerData[questionNumber]);
    } else if (value === "no") {
      setQuestionSelected(questionsAnswerData[questionNumber]);
    } else if (value === "Got it") {
      setShowAndHideQuestion(false);
    }
  };

  useEffect(() => {
    const { email, firstName, lastName } = contactRedux;

    if (answer === "yes") {
      updateDataBrevoMappingStep(email, firstName, lastName, listIds);
    }
  }, [listIds, questionSelected, contactRedux]);

  return (
    <>
      {questionSelected?.icon && (
        <div
          className="icon-qs-aw"
          style={{ textAlign: "center", marginBottom: "10px" }}
        >
          <img src={questionSelected?.icon} alt="" />
        </div>
      )}

      <h3 className="question">{questionSelected?.question ?? ""}</h3>

      <div className="answer">
        {questionSelected?.answer &&
          questionSelected.answer.map((item, key) => (
            <button
              key={key}
              data-value={item.value}
              className={
                item.value === "yes" || item.value === "Got it" ? "active" : ""
              }
              onClick={handleAnswer}
            >
              {item?.name}
            </button>
          ))}
      </div>
    </>
  );
};
