export const currentCompanyDurationOptions = [
  {
    label: "5 years or more",
    value: "60"
  },
  {
    label: "3 - 4 years",
    value: "48"
  },
  {
    label: "1 - 2 years",
    value: "24"
  },
  {
    label: "3 - 12 months",
    value: "12"
  },
  {
    label: "3 months or less",
    value: "3"
  }
];
