import React from "react";
import "./HowItWork.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { Pagination } from "swiper/core";

import img1 from "../../assets/images/howItWork/img1.png";
import img2 from "../../assets/images/howItWork/img2.png";
import img3 from "../../assets/images/howItWork/img3.png";

const HowItWork = () => {
  SwiperCore.use([Pagination]);
  return (
    <div className="how-it-work" id="howItWork">
      <div className="container">
        <h2 className="title" data-aos="fade-left">
          How it works
        </h2>
        <Swiper
          spaceBetween={50}
          slidesPerView={3}
          data-aos="fade-left"
          pagination={{ clickable: true }}
          breakpoints={{
            0: {
              // width: 576,
              slidesPerView: 1,
              dots: true,
              autoplay: { delay: 3000 }
            },
            1024: {
              // width: 768,
              slidesPerView: 3
            }
          }}
        >
          <SwiperSlide>
            <div className="card-image">
              <img src={img1} alt="" />
            </div>
            <div className="card-content">
              <h3 className="title">Complete the form</h3>
              <p className="text">
                The whole process is super simple, time-saving and secure with
                the online submission.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card-image">
              <img src={img2} alt="" />
            </div>
            <div className="card-content">
              <h3 className="title">Fast response</h3>
              <p className="text">
                Your loan request will be reviewed and approved quickly if you
                meet all the lender's criteria.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card-image">
              <img src={img3} alt="" />
            </div>
            <div className="card-content">
              <h3 className="title">Receive the funds</h3>
              <p className="text">
                Money is ready in your bank account when your request is
                completely processed by the lender.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default HowItWork;
