import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./DoNotSellInformation.scss";
import { stateResidencyPromptOptions, stateOptions } from "./options";

import { useForm, Controller } from "react-hook-form";

import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
// CUSTOM STYLE
import { makeStyles } from "@material-ui/core/styles";
import { publicRequest } from "../../../requestMethods";
import { Link } from "react-router-dom";
const useStyles = makeStyles({
  formControl: {
    "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
      color: "#A6A7A8",
      fontFamily: "Inter",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal"
    }
  },
  select: {
    marginBottom: "20px",
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#33A8FC"
      },
      "&:hover fieldset": {
        borderColor: "#33A8FC"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#33A8FC"
      }
    }
  },
  label: {
    background: "#fff !important",
    paddingRight: "3px !important",
    color: "#A6A7A8 !important",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    "&.Label.Mui-focused": {
      color: "#33A8FC"
    }
  },
  menuItem: {
    "&.MuiButtonBase-root": {
      color: "#000",
      fontFamily: "Inter",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      justifyContent: "center"
    },
    "&.Mui-selected": {
      backgroundColor: "#33A8FC !important",
      color: "#fff"
    },
    "&.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover": {
      backgroundColor: "#33A8FC !important",
      color: "#fff"
    },
    "&.MuiOutlinedInput-notchedOutline": {
      borderColor: "#33A8FC !important",
      color: "#fff"
    }
  },
  textField: {
    marginBottom: "20px !important",
    "& .MuiInputBase-input": {
      color: "#000 !important",
      fontFamily: "Inter",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal"
    },
    "& .MuiFormLabel-root": {
      backgroundColor: "#fff",
      paddingRight: "3px",
      color: "#A6A7A8 !important",
      fontFamily: "Inter",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal"
    },

    "& .Label.Mui-focused": {
      color: "#33A8FC !important"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#33A8FC"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#33A8FC"
      },
      "&:hover fieldset": {
        borderColor: "#33A8FC !important"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#33A8FC !important"
      }
    }
  },
  // RESPONSIVE
  "@media (max-width: 768px)": {
    formControl: {
      marginBottom: "15px",
      "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
        fontSize: "14px"
      }
    },
    select: {
      "&.MuiOutlinedInput-root": {
        fontSize: "14px"
      }
    },
    menuItem: {
      "&.MuiButtonBase-root": {
        fontSize: "14px"
      }
    },
    textField: {
      marginBottom: "15px !important",
      "& .MuiInputBase-input": {
        fontSize: "14px"
      },
      "& .MuiFormLabel-root": {
        fontSize: "14px"
      }
    },
    label: {
      fontSize: "14px !important",
      padding: "0 5px !important"
    }
  }
});

const DoNotSellInformation = () => {
  // const navigate = useNavigate();
  const classes = useStyles();
  const windowWidth = window.innerWidth;
  const [loadingButton, setLoadingButton] = useState(false);
  const [stateResidencyPrompt, setStateResidencyPrompt] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");

  // STYLE
  const btnSubmitStyle = {
    marginBottom: windowWidth > 756 ? "20px" : 0,
    width: "100%",
    height: windowWidth > 756 ? "50px" : "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    borderRadius: "5px",
    background: "#F4C811",

    color: "#000",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: windowWidth > 756 ? "25px" : "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "uppercase"
  };

  const handleCheckZipCode = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, "");
    setZipCode(value);
  };

  const handleCheckPhoneNumber = (e) => {
    let value = e.target.value;

    // Xóa tất cả các ký tự không phải số khỏi chuỗi
    value = value.replace(/\D/g, "");

    // Áp dụng định dạng (123)-123-1234
    if (value.length > 0) {
      if (value.length <= 3) {
        value = `(${value}`;
      } else if (value.length <= 6) {
        value = `(${value.slice(0, 3)})-${value.slice(3)}`;
      } else {
        value = `(${value.slice(0, 3)})-${value.slice(3, 6)}-${value.slice(
          6,
          10
        )}`;
      }
    }

    setPhone(value);
  };

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    setLoadingButton(!loadingButton);

    const res = await publicRequest.post(`do-not-sell-information`, data);
    // const res = await updateContact(dispath, contactRedux._id, data);
    if (res?.data?.status === 200) {
      Swal.fire({
        title: res?.data?.message,
        icon: "success",
        confirmButtonText: "OK"
      });
    } else {
      Swal.fire({
        title: res?.data?.message,
        icon: "error",
        confirmButtonText: "OK"
      });
    }
    setLoadingButton(false);
  };

  return (
    <div className="DoNotSellInformation">
      <Navbar />

      <div className="container-privacy">
        <form className="box-privacy" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title">Do not sell my information</h2>
          <p className="text">
            This form allows California, Nevada, Virginia, Utah and Connecticut
            residents to submit certain data rights requests across the contact
            us . Please use this form if you are a California, Nevada, Virginia,
            Utah or Connecticut resident requesting more information related to
            your rights.
          </p>
          <p className="text">
            For any additional questions or rights requests, please email us at{" "}
            <b>
              <Link target="_blank" to="mailto:contact@seekerloans.com">
                contact@seekerloans.com
              </Link>
            </b>
          </p>
          <div className="select-field">
            <label className="label-ct">
              If you are a California, Nevada, Virginia, Utah or Connecticut
              Resident, please select a request:
            </label>
            {/* stateResidencyPrompt */}
            <Controller
              name="stateResidencyPrompt"
              defaultValue={stateResidencyPrompt}
              control={control}
              rules={{ required: "Please select an answer!" }}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  error={!!errors?.stateResidencyPrompt}
                  className={classes.formControl}
                  size={windowWidth > 768 ? "medium" : "small"}
                >
                  {!stateResidencyPrompt && (
                    <InputLabel className={classes.label}>
                      Select one
                    </InputLabel>
                  )}
                  <Select
                    className={classes.select}
                    {...field}
                    onChange={(e) => {
                      const value = e.target.value;
                      field.onChange(value);
                      setStateResidencyPrompt(value);
                    }}
                    value={stateResidencyPrompt}
                    displayEmpty
                  >
                    {/* <MenuItem disabled value="">
                      Select one
                    </MenuItem> */}
                    {stateResidencyPromptOptions.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option}
                        className={classes.menuItem}
                        style={{
                          minHeight: windowWidth > 768 ? "38px" : "36px",
                          fontSize: windowWidth > 768 ? "16px" : "14px",
                          border: "0.01px solid rgb(5 4 4 / 8%)"
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    style={{
                      marginTop: "-20px",
                      marginBottom: "20px"
                    }}
                  >
                    {errors?.stateResidencyPrompt &&
                      errors?.stateResidencyPrompt.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </div>
          {/* end stateResidencyPrompt */}
          {/* firstName */}
          <div className="input-field">
            <label className="label-ct">First Name</label>
            <Controller
              name="firstName"
              control={control}
              defaultValue={firstName}
              rules={{
                required: "First Name is required!"
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="firstName"
                  autoComplete="firstName"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.firstName}
                  emptylabel="John"
                  placeholder="John"
                  helperText={errors?.firstName && errors?.firstName?.message}
                  inputProps={{
                    onInput: (e) => {
                      setFirstName(e.target.value);
                    }
                  }}
                  value={firstName}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end firstName */}
          {/* middleName */}
          <div className="input-field">
            <label className="label-ct">Middle Name</label>
            <Controller
              name="middleName"
              control={control}
              defaultValue={middleName}
              rules={{
                required: "Middle Name is required!"
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="middleName"
                  autoComplete="middleName"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.middleName}
                  emptylabel="Optional"
                  placeholder="Optional"
                  helperText={errors?.middleName && errors?.middleName?.message}
                  inputProps={{
                    onInput: (e) => {
                      setMiddleName(e.target.value);
                    }
                  }}
                  value={middleName}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end middleName */}
          {/* Last Name */}
          <div className="input-field">
            <label className="label-ct">Last Name</label>
            <Controller
              name="lastName"
              control={control}
              defaultValue={lastName}
              rules={{
                required: "Last Name is required!"
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="lastName"
                  autoComplete="lastName"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.lastName}
                  emptylabel="Joe"
                  placeholder="Joe"
                  helperText={errors?.lastName && errors?.lastName?.message}
                  inputProps={{
                    onInput: (e) => {
                      setLastName(e.target.value);
                    }
                  }}
                  value={lastName}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end Last Name */}
          {/* Email address */}
          <div className="input-field">
            <label className="label-ct">Email address</label>
            <Controller
              name="email"
              control={control}
              defaultValue={email}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid email address"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="email"
                  autoComplete="email"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.email}
                  emptylabel="johnjoe@gmail.com"
                  placeholder="johnjoe@gmail.com"
                  helperText={errors?.email && errors?.email?.message}
                  inputProps={{
                    onInput: (e) => setEmail(e.target.value)
                  }}
                  value={email}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end Email address */}
          {/* Mobile number */}
          <div className="input-field">
            <label className="label-ct">Mobile number</label>
            <Controller
              name="phone"
              control={control}
              defaultValue={phone}
              rules={{
                required: "Phone number is required",
                minLength: {
                  value: 10,
                  message: "Phone number must be 10 characters long"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="phone"
                  autoComplete="phone"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.phone}
                  emptylabel={!phone ? "(123)-123-1234" : ""}
                  placeholder={!phone ? "(123)-123-1234" : "Phone Number"}
                  helperText={errors?.phone && errors?.phone?.message}
                  inputProps={{
                    maxLength: 14,
                    onInput: handleCheckPhoneNumber
                  }}
                  value={phone}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end Mobile number */}
          {/* Country */}
          <div className="input-field">
            <label className="label-ct">Country</label>
            <Controller
              name="country"
              control={control}
              defaultValue={country}
              rules={{
                required: "Country is required!"
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="country"
                  autoComplete="country"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.country}
                  emptylabel="United States"
                  placeholder="United States"
                  helperText={errors?.country && errors?.country?.message}
                  inputProps={{
                    onInput: (e) => {
                      setCountry(e.target.value);
                    }
                  }}
                  value={country}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end  Country */}
          {/* Zip Code */}
          <div className="input-field">
            <label className="label-ct">Zip Code</label>
            <Controller
              name="zipCode"
              control={control}
              defaultValue={zipCode}
              rules={{
                required: "Zip code is required",
                minLength: {
                  value: 5,
                  message: "Zip code must be 5 characters long"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="zipCode"
                  autoComplete="zipCode"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.zipCode}
                  emptylabel="32003"
                  placeholder="32003"
                  helperText={errors?.zipCode && errors?.zipCode?.message}
                  inputProps={{
                    maxLength: 5,
                    onInput: handleCheckZipCode
                  }}
                  value={zipCode}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end Zip Code */}
          {/* State */}
          <div className="input-field">
            <label className="label-ct">State</label>
            <Controller
              name="state"
              defaultValue={state}
              control={control}
              rules={{ required: "State Type is required!" }}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  error={!!errors?.state}
                  className={classes.formControl}
                  size={windowWidth > 768 ? "medium" : "small"}
                >
                  {!state && (
                    <InputLabel className={classes.label}>Florida</InputLabel>
                  )}
                  <Select
                    className={classes.select}
                    {...field}
                    onChange={(e) => {
                      const value = e.target.value;
                      field.onChange(value);
                      setState(value);
                    }}
                    value={state}
                    displayEmpty
                  >
                    {/* <MenuItem disabled value="">
                      Select one
                    </MenuItem> */}
                    {stateOptions.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.value}
                        className={classes.menuItem}
                        style={{
                          minHeight: windowWidth > 768 ? "38px" : "36px",
                          fontSize: windowWidth > 768 ? "16px" : "14px",
                          border: "0.01px solid rgb(5 4 4 / 8%)"
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    style={{
                      marginTop: "-20px",
                      marginBottom: "20px"
                    }}
                  >
                    {errors?.state && errors?.state.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </div>
          {/* end State */}
          {/* Address */}
          <div className="input-field">
            <label className="label-ct">Address</label>
            <Controller
              name="address"
              control={control}
              defaultValue={address}
              rules={{
                required: "Address is required",
                minLength: {
                  value: 5,
                  message: "Address must be 5 characters long"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="address"
                  autoComplete="address"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.address}
                  emptylabel="Hickman Drive"
                  placeholder="Hickman Drive"
                  helperText={errors?.address && errors?.address?.message}
                  inputProps={{
                    onInput: (e) => {
                      setAddress(e.target.value);
                    }
                  }}
                  value={address}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end Address */}
          {/* City */}
          <div className="input-field">
            <label className="label-ct">City</label>
            <Controller
              name="city"
              control={control}
              defaultValue={city}
              rules={{
                required: "City is required"
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="city"
                  autoComplete="city"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.city}
                  emptylabel="Sanford"
                  placeholder="Sanford"
                  helperText={errors?.city && errors?.city?.message}
                  inputProps={{
                    onInput: (e) => {
                      setCity(e.target.value);
                    }
                  }}
                  value={city}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end City */}
          <LoadingButton
            endIcon={<SendIcon />}
            loading={loadingButton}
            loadingPosition="end"
            variant="contained"
            fullWidth
            size="large"
            style={btnSubmitStyle}
            onClick={() => {
              handleSubmit(onSubmit);
            }}
            type="submit"
          >
            <span>REQUEST NOW</span>
          </LoadingButton>
        </form>
      </div>

      <Footer desc={false} />
    </div>
  );
};

export default DoNotSellInformation;
