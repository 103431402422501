import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step12.scss";
import { currentCompanyDurationOptions } from "./options";
import Progress from "../../../components/Progress/Progress";

import { PrevBtn } from "../../../components/Button/PrevBtn/PrevBtn";

import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
const Step12 = () => {
  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [creditScoreRating, setCreditScoreRating] = useState(
    contactRedux?.creditScoreRating ?? " "
  );
  const windowWidth = window.innerWidth;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // custom
  const { handleSubmit } = useForm();
  const onSubmit = async () => {
    const res = await updateContact(dispatch, contactRedux._id, {
      creditScoreRating: creditScoreRating
    });

    if (res?.status === 200) {
      setTimeout(() => {
        navigate("/step13");
      }, 300);
    }
  };

  useEffect(() => {
    windowWidth < 768 && window.scrollTo(0, 66);
  });

  return (
    <div className="step-12">
      <Navbar />
      <div className="container">
        <Progress step={12} />
      </div>
      <div className="step-main">
        <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title">How do you rate your credit score?</h2>

          <div className="form-control">
            <div className="list-btn-radio">
              {currentCompanyDurationOptions.map((option, index) => (
                <button
                  key={index}
                  className={`btn-radio ${
                    creditScoreRating === option.value ? "active" : ""
                  }`}
                  onClick={(e) => {
                    setCreditScoreRating(option.value);
                    // onSubmit(e, option.value);
                  }}
                  defaultValue={creditScoreRating}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>

          <div className="group-btn">
            <PrevBtn className="btn-prev" to="/step11" />
          </div>
        </form>
      </div>
      <Footer desc={false} />
    </div>
  );
};

export default Step12;
