import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step19.scss";
import Progress from "../../../components/Progress/Progress";

import { PrevBtn } from "../../../components/Button/PrevBtn/PrevBtn";

import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";

import { useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { Helmet } from "react-helmet";

const ScriptPixel = () => {
  const facebookPixelScript = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '1012184440040805');
    fbq('track', 'PageView');
  `;

  const noscriptContent = `
    <img
      height="1"
      width="1"
      style="display:none"
      src="https://www.facebook.com/tr?id=1012184440040805&ev=PageView&noscript=1"
      alt="Facebook Pixel"
    />
  `;

  return (
    <div>
      <Helmet>
        {/* Meta Pixel Code */}
        <script>{facebookPixelScript}</script>
        <noscript dangerouslySetInnerHTML={{ __html: noscriptContent }} />
        {/* End Meta Pixel Code */}
      </Helmet>
      {/* Các thành phần và nội dung khác của ứng dụng của bạn */}
    </div>
  );
};

const Step19 = () => {
  const unsecuredDebtAmountOptions = [
    "None",
    "$1,000-$4,999",
    "$5,000-$9,999",
    "$10,000-$20,000",
    "Over $20,000"
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingButton, setLoadingButton] = useState(false);
  const windowWidth = window.innerWidth;

  const [progressStep, setProgressStep] = useState(19);

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [unsecuredDebtAmount, setUnsecuredDebtAmount] = useState(
    contactRedux?.unsecuredDebtAmount ?? ""
  );

  useEffect(() => {
    windowWidth < 768 && window.scrollTo(0, 66);
  });

  // STYLE
  const btnSubmitStyle = {
    marginBottom: windowWidth > 756 ? "20px" : 0,
    width: "100%",
    height: windowWidth > 756 ? "50px" : "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    borderRadius: "3px",

    background: "#F4C811",

    color: "#000",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: windowWidth > 756 ? "25px" : windowWidth > 400 ? "16px" : "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "uppercase"
  };

  // custom
  const {
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    setLoadingButton(true);

    if (!unsecuredDebtAmount) {
      setError("unsecuredDebtAmount", {
        type: "invalid",
        message: "Please select your source of income."
      });
      return;
    }

    const res = await updateContact(dispatch, contactRedux._id, {
      unsecuredDebtAmount: unsecuredDebtAmount
    });

    // SEND DATA TO PHONEXA
    setLoadingButton(false);

    if (res?.status === 200) {
      setProgressStep(20);

      setTimeout(() => {
        setLoadingButton(false);
        navigate("/congratulations");
      }, 1200);
    }
  };

  return (
    <div className="step-19">
      <ScriptPixel />
      <Navbar />
      <div className="container">
        <Progress step={progressStep} />
      </div>
      <div className="step-main">
        <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title">How much unsecured debt do you have to pay?</h2>

          <div className="form-control">
            <div className="list-btn-radio">
              {unsecuredDebtAmountOptions.map((option, index) => (
                <button
                  key={index}
                  className={`btn-radio ${
                    unsecuredDebtAmount === option ? "active" : ""
                  }`}
                  onClick={(e) => {
                    setUnsecuredDebtAmount(option);
                    clearErrors("unsecuredDebtAmount");
                  }}
                >
                  {option}
                </button>
              ))}
            </div>
            {errors?.unsecuredDebtAmount && (
              <span className="error">
                {errors.unsecuredDebtAmount.message}
              </span>
            )}
          </div>

          <div className="group-btn">
            <PrevBtn className="btn-prev" to="/step18" />
            <LoadingButton
              endIcon={<SendIcon />}
              loading={loadingButton}
              loadingPosition="end"
              variant="contained"
              fullWidth
              size="large"
              style={btnSubmitStyle}
              onClick={(e) => {
                handleSubmit(onSubmit);
              }}
              type="submit"
            >
              <span>COMPLETE THE REQUEST</span>
            </LoadingButton>
          </div>
        </form>
      </div>
      <Footer desc={false} />
    </div>
  );
};

export default Step19;
