import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Unsubscribe.scss";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";

import { makeStyles } from "@material-ui/core/styles";
import { publicRequest } from "../../../requestMethods";
const useStyles = makeStyles({
  formControl: {
    "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
      color: "#A6A7A8",
      fontFamily: "Inter",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal"
    }
  },
  select: {
    marginBottom: "20px",
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#33A8FC"
      },
      "&:hover fieldset": {
        borderColor: "#33A8FC"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#33A8FC"
      }
    }
  },
  label: {
    background: "#fff !important",
    paddingRight: "3px !important",
    color: "#A6A7A8 !important",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    "&.Label.Mui-focused": {
      color: "#33A8FC"
    }
  },
  menuItem: {
    "&.MuiButtonBase-root": {
      color: "#000",
      fontFamily: "Inter",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      justifyContent: "center"
    },
    "&.Mui-selected": {
      backgroundColor: "#33A8FC !important",
      color: "#fff"
    },
    "&.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover": {
      backgroundColor: "#33A8FC !important",
      color: "#fff"
    },
    "&.MuiOutlinedInput-notchedOutline": {
      borderColor: "#33A8FC !important",
      color: "#fff"
    }
  },
  textField: {
    marginBottom: "20px !important",
    "& .MuiInputBase-input": {
      color: "#000 !important",
      fontFamily: "Inter",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal"
    },
    "& .MuiFormLabel-root": {
      backgroundColor: "#fff",
      paddingRight: "3px",
      color: "#A6A7A8 !important",
      fontFamily: "Inter",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal"
    },

    "& .Label.Mui-focused": {
      color: "#33A8FC !important"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#33A8FC"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#33A8FC"
      },
      "&:hover fieldset": {
        borderColor: "#33A8FC !important"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#33A8FC !important"
      }
    }
  },
  // RESPONSIVE
  "@media (max-width: 768px)": {
    formControl: {
      marginBottom: "15px",
      "& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
        fontSize: "14px"
      }
    },
    select: {
      "&.MuiOutlinedInput-root": {
        fontSize: "14px"
      }
    },
    menuItem: {
      "&.MuiButtonBase-root": {
        fontSize: "14px"
      }
    },
    textField: {
      marginBottom: "15px !important",
      "& .MuiInputBase-input": {
        fontSize: "14px"
      },
      "& .MuiFormLabel-root": {
        fontSize: "14px"
      }
    },
    label: {
      fontSize: "14px !important"
    }
  }
});

const Unsubscribe = () => {
  // eslint-disable-next-line no-unused-vars
  const windowWidth = window.innerWidth;
  const classes = useStyles();
  const [loadingButton, setLoadingButton] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [unsubscribe, setUnsubscribe] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const unsubscribeOptions = [
    { value: "Data Deletion", label: "Data Deletion" },
    { value: "Data Access", label: "Data Access" },
    { value: "Right To Know", label: "Right To Know" },
    { value: "Do Not Sell My Information", label: "Do Not Sell My Information" }
  ];

  // STYLE
  const btnSubmitStyle = {
    marginBottom: windowWidth > 756 ? "20px" : 0,
    width: "100%",
    height: windowWidth > 756 ? "50px" : "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    borderRadius: "5px",
    background: "#F4C811",

    color: "#000",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: windowWidth > 756 ? "25px" : "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "uppercase"
  };

  const handleCheckPhoneNumber = (e) => {
    let value = e.target.value;

    // Xóa tất cả các ký tự không phải số khỏi chuỗi
    value = value.replace(/\D/g, "");

    // Áp dụng định dạng (123)-123-1234
    if (value.length > 0) {
      if (value.length <= 3) {
        value = `(${value}`;
      } else if (value.length <= 6) {
        value = `(${value.slice(0, 3)})-${value.slice(3)}`;
      } else {
        value = `(${value.slice(0, 3)})-${value.slice(3, 6)}-${value.slice(
          6,
          10
        )}`;
      }
    }

    setPhone(value);
  };

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    setLoadingButton(!loadingButton);

    const res = await publicRequest.post(`unsubscribe`, data);
    // const res = await updateContact(dispath, contactRedux._id, data);
    if (res.data.status === 200) {
      Swal.fire({
        title: res?.data?.message,
        icon: "success",
        confirmButtonText: "OK"
      });
    } else {
      Swal.fire({
        title: res?.data?.message,
        icon: "error",
        confirmButtonText: "OK"
      });
    }
    setLoadingButton(false);
  };

  return (
    <div className="unsubscribe">
      <Navbar />

      <div className="container-privacy">
        <form className="box-privacy" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title">Unsubscribe</h2>
          <p className="text">
            If you have been receiving unwanted messages from Seekerloans.com,
            use this form to unsubscribe. Opting out will take up to 48 hours.
          </p>

          <div className="input-field">
            <label className="label-ct">Email address</label>
            <Controller
              name="email"
              control={control}
              defaultValue={email}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "invalid email address"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="email"
                  autoComplete="email"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.email}
                  placeholder="johnjoe@gmail.com"
                  helperText={errors?.email && errors?.email?.message}
                  inputProps={{
                    onInput: (e) => setEmail(e.target.value)
                  }}
                  value={email}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>

          {/* Mobile number */}
          <div className="input-field">
            <label className="label-ct">Mobile number</label>
            <Controller
              name="phone"
              control={control}
              defaultValue={phone}
              rules={{
                required: "Phone number is required",
                minLength: {
                  value: 10,
                  message: "Phone number must be 10 characters long"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={ref}
                  id="phone"
                  autoComplete="phone"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.phone}
                  // emptylabel={!phone ? "(123)-123-1234" : ""}
                  placeholder={!phone ? "(123)-123-1234" : "Phone Number"}
                  helperText={errors?.phone && errors?.phone?.message}
                  inputProps={{
                    maxLength: 14,
                    onInput: handleCheckPhoneNumber
                  }}
                  value={phone}
                  className={classes.textField}
                  size={windowWidth > 768 ? "medium" : "small"}
                />
              )}
            />
          </div>
          {/* end Mobile number */}

          <div className="select-field">
            <label className="label-ct">Why do you want to unsubscribe?</label>
            {/* Unsubscribe  */}
            <Controller
              name="unsubscribe"
              defaultValue={unsubscribe}
              control={control}
              rules={{
                required: "Unsubscribe is required"
              }}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  error={!!errors?.unsubscribe}
                  className={classes.formControl}
                  size={windowWidth > 768 ? "medium" : "small"}
                >
                  {!unsubscribe && (
                    <InputLabel className={classes.label}>
                      Select one
                    </InputLabel>
                  )}
                  <Select
                    className={classes.select}
                    {...field}
                    onChange={(e) => {
                      const value = e.target.value;
                      field.onChange(value);
                      setUnsubscribe(value);
                      setIsFocused(!isFocused);
                    }}
                    displayEmpty
                    onFocus={() => setIsFocused(!isFocused)}
                  >
                    {unsubscribeOptions.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.value}
                        className={classes.menuItem}
                        style={{
                          minHeight: windowWidth > 768 ? "38px" : "36px",
                          fontSize: windowWidth > 768 ? "16px" : "14px",
                          border: "0.01px solid rgb(5 4 4 / 8%)"
                        }}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    style={{
                      marginTop: "-20px",
                      marginBottom: "20px"
                    }}
                  >
                    {errors?.unsubscribe && errors?.unsubscribe.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </div>
          <LoadingButton
            endIcon={<SendIcon />}
            loading={loadingButton}
            loadingPosition="end"
            variant="contained"
            fullWidth
            size="large"
            style={btnSubmitStyle}
            onClick={() => {
              handleSubmit(onSubmit);
            }}
            type="submit"
          >
            <span>REQUEST NOW</span>
          </LoadingButton>
        </form>
      </div>

      <Footer desc={false} />
    </div>
  );
};

export default Unsubscribe;
