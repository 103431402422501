import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Configuration.scss";
import logo from "../../../assets/images/logo.png";
import active1 from "../../../assets/images/configuration/progress/1-active.png";
import active2 from "../../../assets/images/configuration/progress/2-active.png";
import active3 from "../../../assets/images/configuration/progress/3-active.png";
import active4 from "../../../assets/images/configuration/progress/4-active.png";
import { sendDataPhonexa } from "../../../utils/callApi";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Box } from "@material-ui/core";
import NumberLoader from "../../../components/CountingComponent/CountingComponent";
import { QuestionAnswer } from "../../../components/QuestionAnswer/QuestionAnswer";
import MyCircularProgressbar from "../../../components/CircularProgressbar/CircularProgressbar";

const questionsAnswerData = [
  {
    questionNumber: 1,
    question:
      "Do you have more than 15k in credit card and personal loan debts?",
    answer: [
      {
        name: "Yes",
        value: "yes"
      },
      {
        name: "No",
        value: "no"
      }
    ]
  },
  {
    questionNumber: 2,
    question: "Looking to save on auto insurance?",
    answer: [
      {
        name: "Yes",
        value: "yes"
      },
      {
        name: "No",
        value: "no"
      }
    ]
  },
  {
    questionNumber: 3,
    question: "Are you looking to improve your credit score?",
    answer: [
      {
        name: "Yes",
        value: "yes"
      },
      {
        name: "NO",
        value: "no"
      }
    ]
  },
  {
    questionNumber: 4,
    question: "Thank you for your time! Check your inbox for updates.",
    answer: [
      {
        name: "Got it",
        value: "Got it"
      }
    ],
    // icon: checkedIcon
  }
];


const Configuration = () => {
  const isMobile = window.innerWidth < 768;

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [step, setStep] = useState(0);
  const [callApiComplete, setCallApiComplete] = useState(false);
  const [showAndHideQuestion, setShowAndHideQuestion] = useState(true);
  const [questionSelected, setQuestionSelected] = useState(
    questionsAnswerData[0]
  );

  const steps = [
    {
      number: 1,
      icon: active1,
      name: "Verifying Loan Request",
      verified: "Verify Loan Request",
      verifiedComplete: "Verified!",
      timeCompleted: 3000
    },
    {
      number: 2,
      icon: active2,
      name: "Analyzing your information",
      verified: "Analysing your information",
      verifiedComplete: "Analysed!",
      timeCompleted: 3100
    },
    {
      number: 3,
      icon: active3,
      name: "Searching Lenders",
      verified: "Searching Lenders",
      verifiedComplete: "Verified!",
      timeCompleted: 350000
    },
    {
      number: 4,
      icon: active4,
      name: step === 3 ? "Connecting Lenders" : "Connecting Lenders",
      verified: "Connecting Lenders 4",
      verifiedComplete: "Congratulations! You are connecting with the Lender."
    }
  ];

  const callApi = async () => {
    try {
      const res = await sendDataPhonexa(contactRedux);
      return res;
    } catch (error) {
      console.error("Error sending data: ", error);
      throw error;
    }
  };

  const nextStep = async () => {
    if (step < steps.length - 1) {
      setStep(step + 1);
    }
  };
  const handleApiCall = async () => {
    setCallApiComplete(false);
    try {
      const res = await callApi();
      setCallApiComplete(true);
      return res;
    } catch (error) {
      setCallApiComplete(true);
      return null;
    }
  };
  useEffect(() => {
    if (step === 0) {
      handleApiCall()
        .then((res) => {
          setTimeout(() => {
            setStep(3);
            const redirectURL = res.data?.redirect_url ?? res.data?.rejectUrl;
            if (res.data?.status_text === "sold") {
              localStorage.setItem("redirectURL", redirectURL);
              setTimeout(() => {
                window.location.href = redirectURL;
              }, 500);
            }
            if (res.data?.status_text === "reject") {
              if (redirectURL) window.location.href = redirectURL;
            }
          }, 1000);
        })
        .catch(() => {
          // Xử lý lỗi ở đây nếu cần
        });
    }
  }, [step]);

  useEffect(() => {
    if (steps[step].timeCompleted) {
      if (step < steps.length) {
        const timer = setTimeout(() => {
          nextStep();
        }, steps[step].timeCompleted + 500);

        return () => clearTimeout(timer);
      }
    }
  }, [step, steps]);

  return (
    <Box
      className="configuration-page"
      style={{
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100vh"
      }}
      sx={{
        // background:
        //   "linear-gradient(\n    180deg,\n    #f1ffec 0.56%,\n    rgba(241, 255, 236, 0) 57.94%\n  )"
      }}
    >
      <nav>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
      </nav>

      <div className="step-main">
        <div className="container">
          <h1 className="h1-title">Please do not close this window</h1>
          <h3 className="h3-desc">
            Hi {contactRedux?.firstName}, your request is being processed now!
            Once connected, you'll be redirected to a lender's website.
          </h3>
          <Box
            sx={{
              width: "100%",
              maxWidth: "900px",
              padding: isMobile ? "25px 15px 30px" : "41px 30px 52px",
              boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              border: "1px solid #33A8FC",
              backgroundColor: "#fff"
            }}
          >
            <div className="stepper-wrapper">
              {steps.map((stepInfo, index) => (
                <div
                  key={stepInfo.number}
                  className={`stepper-item ${
                    index < step
                      ? "completed"
                      : index === step
                      ? "active"
                      : "normal"
                  }`}
                >
                  <div className="step-icon">
                    <img src={stepInfo.icon} alt="" />
                  </div>
                  {index === step && (
                    <div className="step-name">{stepInfo.name}</div>
                  )}
                </div>
              ))}
            </div>
            <MyCircularProgressbar
              timeCompleted={callApiComplete ? 500 : steps[step].timeCompleted}
              verified={steps[step].verified}
              verifiedComplete={steps[step].verifiedComplete}
              step={step}
              callApiComplete={callApiComplete}
            />

            {step === 2 && steps[step].verified && (
              <Box className="box-searching">
                <p
                  component="h3"
                  style={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: isMobile ? "12px" : "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    textAlign: "center"
                  }}
                >
                  Searching amount:{" "}
                  <span style={{ color: "#48C619" }}>
                    ${contactRedux?.amount ?? 0}
                  </span>
                </p>
                <p
                  style={{
                    color: "#606060",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: isMobile ? "10px" : "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                    marginBottom: "25px"
                  }}
                >
                  <NumberLoader />
                  /10 Please wait, we require more time to process your request.
                </p>
              </Box>
            )}
          {showAndHideQuestion && (
            <div className="box-question-answer">
              <QuestionAnswer
                questionsAnswerData={questionsAnswerData}
                questionSelected={questionSelected}
                contactRedux={contactRedux}
                setQuestionSelected={setQuestionSelected}
                setShowAndHideQuestion={setShowAndHideQuestion}
              />
            </div>
          )}

            <p className="desc-configuration">
              We will <b>REDIRECT YOU DIRECTLY TO THE LENDER</b> when your
              application is processed successfully. In regard to your rights,
              you should review and acknowledge all policies, terms &
              conditions, rates, fee,...provided by the lender. You are under no
              obligation to accept all offered products/services. This content
              is for informational purposes only and does not work as legal
              consultation or advice.
            </p>
          </Box>
        </div>
      </div>

      <footer>
        <p>© 2023 Seekerloans.com All rights reserved.</p>
      </footer>
    </Box>
  );
};

export default Configuration;
