import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { checkLastStep } from "../../utils/checkLastStep";

import DataProtected from "../../assets/images/security/DataProtected.png";
import Clear from "../../assets/images/security/Clear.png";

import "./Banner.scss";

import LoadingButton from "@mui/lab/LoadingButton";

import { useDispatch, useSelector } from "react-redux";
import { addContact } from "../../redux/apiCalls";
import { useNavigate } from "react-router-dom";

import { RatesFee } from "../popups/RatesFee/RatesFee";

import SendIcon from "@mui/icons-material/Send";
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import {
  ListBoxCustom,
  labelCustom,
  textFieldCustom,
  textInputStyles
} from "./style";

import axios from "axios";

const useStyles = makeStyles({
  textField: {
    marginBottom: "20px !important",
    "& .MuiInputBase-input": {
      color: "#000",
      fontFamily: "Inter",
      // fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal"
    },
    "& .MuiFormLabel-root": {
      paddingRight: "3px",
      color: "#A6A7A8 !important",
      fontFamily: "Inter",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal"
    },

    "& .Label.Mui-focused": {
      color: "#33A8FC"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#33A8FC"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#33A8FC"
      },
      "&:hover fieldset": {
        borderColor: "#33A8FC"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#33A8FC"
      }
    }
  },
  "@media (max-width: 768px)": {
    textField: {
      marginBottom: "15px !important",
      "& .MuiInputBase-input": {
        fontSize: "14px"
      },
      "& .MuiFormLabel-root": {
        fontSize: "14px",
        padding: "5px 0"
      }
    }
  }
});

const Banner = () => {
  // STYLE
  const classes = useStyles();
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const [openRateFee, setOpenRateFee] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const contactRedux = useSelector((state) => state?.contact?.contact);

  const [amount, setAmount] = useState(contactRedux?.amount ?? "");

  const emailDomains = [
    "gmail.com",
    "outlook.com",
    "hotmail.com",
    "aol.com",
    "yahoo.com",
    "icloud.com",
    "yandex.com"
  ];
  const [email, setEmail] = useState(contactRedux?.email ?? "");
  const [emailOptions, setEmailOptions] = useState([]);
  const [openEmailOptions, setOpenEmailOptions] = useState(false);

  const [ipAddress, setIpAddress] = useState(contactRedux?.ipAddress ?? "");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const btnSubmitStyle = {
    marginBottom: windowWidth > 768 ? "20px" : "0",
    width: "100%",
    height: windowWidth > 768 ? "50px" : "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    borderRadius: "2px",
    background: "#F4C811",

    color: "#000",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: windowWidth > 768 ? "25px" : "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "uppercase"
  };

  useEffect(() => {
    const getIpAddress = async () => {
      const yourIp = await axios.get("https://api.seekerloans.com/your-ip");
      const res = yourIp?.data.ip;
      if (res) {
        setIpAddress(res);
      }
    };

    if (!ipAddress) {
      getIpAddress();
    }
  }, [ipAddress]);

  const handleInputChange = (e, newInputValue) => {
    if (newInputValue.includes("@")) {
      const [email, domain] = newInputValue.split("@");
      const filteredOptions = emailDomains
        .filter((option) => option.includes(domain))
        .map((option) => `${email}@${option}`);
      setEmailOptions(filteredOptions);
    } else {
      setEmailOptions([]);
    }
  };
  // SUBMIT FUNC
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm({
    mode: "onChange"
  });
  const onSubmit = async (data) => {
    setLoadingButton(!loadingButton);

    data.ipAddress = ipAddress;
    data.userAgent = navigator.userAgent;

    const res = await addContact(dispatch, data);

    if (res?.status === 200) {
      setLoadingButton(false);
      // CHECK STEP
      const lastStep = checkLastStep(res?.contact);
      const stepsRequiringPayFrequency = [
        "step10",
        "step11",
        "step12",
        "step13",
        "step14",
        "step15",
        "step16",
        "step17",
        "step18",
        "step19"
      ];
      if (stepsRequiringPayFrequency.includes(lastStep)) {
        navigate("/step9");
      } else {
        navigate(lastStep ?? "/step1");
      }
    } else {
      setError("email", {
        type: "error",
        message: res?.message
      });
      setLoadingButton(false);
    }
    return;
  };

  const withValueLimit = (values) => {
    const { formattedValue, floatValue } = values;
    if (formattedValue === "") return true;
    if (formattedValue === "$0") return false;
    if (formattedValue === "-$0") return false;
    return !isNaN(floatValue) && floatValue >= 0 && floatValue <= 5000;
  };

  return (
    <div className="banner" id="banner">
      {openRateFee && (
        <RatesFee openRateFee={openRateFee} setOpenRateFee={setOpenRateFee} />
      )}
      <div className="container">
        {windowWidth < 768 && (
          <h2 className="title">
            Ready To Get Up To $5,000 For Your Loan Request!
          </h2>
        )}
        <div className="form" data-aos="zoom-in-down">
          {windowWidth > 768 && (
            <h2 className="title">
              Ready To Get Up To $5,000 For Your Loan Request!
            </h2>
          )}
          {/* FORM */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="amount"
              control={control}
              defaultValue={amount}
              rules={{
                required: "Loans Amount is required",
                validate: (value) => {
                  const cleanedString = value.replace(/\$|,/g, "");
                  const numericValue = parseFloat(cleanedString);
                  if (numericValue < 100) {
                    return "The loan amount must be greater than $100";
                  }
                  if (numericValue > 5000) {
                    return "You can only borrow a maximum of $5,000";
                  }
                  if (numericValue % 100 !== 0) {
                    return "Enter only multiples of 100, e.g., 100, 200, 300, ...";
                  }
                  return true;
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <FormControl fullWidth>
                  <FormHelperText sx={labelCustom}>
                    Loan Amount ($100 - $5,000)
                  </FormHelperText>
                  <NumericFormat
                    {...field}
                    inputRef={(input) => {
                      input?.addEventListener("focus", () => {
                        input?.setAttribute("inputmode", "numeric");
                      });
                      ref(input);
                    }}
                    prefix="$"
                    id="amount"
                    autoComplete="amount"
                    className={classes.textField}
                    size={windowWidth > 768 ? "medium" : "small"}
                    sx={textFieldCustom}
                    label={amount ? "" : "$500"}
                    fullWidth
                    thousandSeparator
                    customInput={TextField}
                    error={!!errors?.amount}
                    helperText={errors?.amount && errors?.amount?.message}
                    value={amount}
                    onValueChange={(values) => {
                      const { value } = values;
                      setAmount(value);
                    }}
                    InputLabelProps={{
                      shrink: false
                      // style: { ...textInputStyles, color: "#B7B7B7" }
                    }}
                    isAllowed={withValueLimit}
                  />
                </FormControl>
              )}
            />

            <Controller
              name="email"
              control={control}
              defaultValue={email}
              rules={{
                required: "Email is required!",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Invalid email address"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <FormControl
                  fullWidth
                  sx={{
                    marginBottom: isMobile ? "7px" : "15px"
                  }}
                >
                  <FormHelperText sx={labelCustom}>
                    Your email address
                  </FormHelperText>
                  <Autocomplete
                    {...field}
                    freeSolo
                    id="email"
                    name="email"
                    onOpen={() => setOpenEmailOptions(true)}
                    onClose={() => setOpenEmailOptions(false)}
                    disableClearable
                    value={email}
                    options={emailOptions}
                    onChange={(e, option) => {
                      setEmail(option);
                    }}
                    onInputChange={(event, newInputValue) => {
                      handleInputChange(event, newInputValue);
                      field.onChange(newInputValue);
                    }}
                    ListboxComponent="div"
                    ListboxProps={{ sx: ListBoxCustom }}
                    //PopperComponent={isMobile && "bottom"}
                    noOptionsText={false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={email ? "" : "e.g. john123@gmail.com"}
                        size={isMobile ? "small" : "medium"}
                        sx={{
                          ...textFieldCustom
                        }}
                        className={
                          openEmailOptions && emailOptions.length > 0
                            ? "show"
                            : ""
                        }
                        InputLabelProps={{
                          shrink: false,
                          style: {
                            ...textInputStyles,
                            color: "#B7B7B7"
                          }
                        }}
                        value={email}
                        error={!!errors?.email}
                        helperText={errors?.email && errors?.email?.message}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    )}
                  />
                </FormControl>
              )}
            />

            <p className="tcpa">
              By clicking <b>'Get Started'</b>, you agree to our{" "}
              <Link to="/privacy">Privacy Policy</Link>,{" "}
              <Link to="/terms-of-use">Terms</Link>
              {", "}
              <Link onClick={() => setOpenRateFee(!openRateFee)}>
                Rate & Fees
              </Link>{" "}
              and receive special offers from us and our marketing partners via
              email communication
            </p>
            <LoadingButton
              endIcon={<SendIcon />}
              loading={loadingButton}
              loadingPosition="end"
              variant="contained"
              fullWidth
              size="large"
              style={btnSubmitStyle}
              onClick={() => {
                handleSubmit(onSubmit);
              }}
              type="submit"
            >
              <span>Get Started</span>
            </LoadingButton>
          </form>

          {/* FORM */}

          {windowWidth > 768 && (
            <div className="list-security">
              <div className="item">
                <img src={Clear} alt="DataProtected" />
                <p>DATA PROTECTED</p>
              </div>

              <div className="item">
                <img src={DataProtected} alt="DataProtected" />
                <p>ENCRYPTED DATA</p>
              </div>
            </div>
          )}
        </div>
        {windowWidth < 768 && (
          <div className="list-security">
            <div className="item">
              <img src={Clear} alt="DataProtected" />
              <p>DATA PROTECTED</p>
            </div>
            <div className="item">
              <img src={DataProtected} alt="DataProtected" />
              <p>ENCRYPTED DATA</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
