export const monthlyIncomeOptions = [
  { label: "More than $5,500", value: "6000" },
  { label: "$4,501 - $5,500", value: "5000" },
  { label: "$3,501 - $4,500", value: "4000" },
  { label: "$2,501 - $3,500", value: "3000" },
  { label: "$1,501 - $2,500", value: "2000" },
  { label: "Less than $1,500", value: "1000" }
];

export const payFrequencyOptions = [
  { label: "Every 2 weeks", value: "BIWEEKLY" },
  { label: "Twice a month", value: "TWICEMONTHLY" },
  { label: "Monthly", value: "MONTHLY" },
  { label: "Weekly", value: "WEEKLY" }
];
