import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step18.scss";
import Progress from "../../../components/Progress/Progress";

import { PrevBtn } from "../../../components/Button/PrevBtn/PrevBtn";
import SendIcon from "@mui/icons-material/Send";

import LoadingButton from "@mui/lab/LoadingButton";

import { updateContact } from "../../../redux/apiCalls";

import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { PatternFormat } from "react-number-format";

import { FormControl, FormHelperText, TextField } from "@mui/material";

import {
  btnSubmitCustom,
  labelCustom,
  textFieldCustom,
  textInputStyles
} from "./style";

const Step18 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadingButton, setLoadingButton] = useState(false);
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [socialSecurityNumber, setSocialSecurityNumber] = useState(
    contactRedux?.socialSecurityNumber ?? ""
  );

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    setLoadingButton(!loadingButton);

    const res = await updateContact(dispatch, contactRedux?._id, data);

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/step19");
      }, 300);
    }
  };

  return (
    <div className="step-18">
      <Navbar />
      <div className="container">
        <Progress step={18} />
      </div>
      <div className="step-main">
        <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title">Social Security Number</h2>
          <h5>Many lenders need SSN to consider a loan.</h5>
          <div className="form-control">
            {/* socialSecurityNumber */}
            <Controller
              name="socialSecurityNumber"
              control={control}
              defaultValue={socialSecurityNumber}
              rules={{
                required: "Social Security Number is required!",
                pattern: {
                  value: /^\d{3}-\d{2}-\d{4}$/,
                  message: "Invalid social security number format xxx-xx-xxxx"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <FormControl
                  fullWidth
                  sx={{
                    marginBottom: "15px"
                  }}
                >
                  <FormHelperText sx={labelCustom}>
                    Social Security Number
                  </FormHelperText>
                  <PatternFormat
                    customInput={TextField}
                    format="###-##-####"
                    //
                    {...field}
                    inputRef={(input) => {
                      input?.addEventListener("focus", () => {
                        input?.setAttribute("inputmode", "numeric");
                      });
                      ref(input);
                    }}
                    id="socialSecurityNumber"
                    // autoComplete="socialSecurityNumber"
                    variant="outlined"
                    fullWidth
                    type="text"
                    error={!!errors?.socialSecurityNumber}
                    placeholder="e.g. 123 - 12 - 1234"
                    helperText={
                      errors?.socialSecurityNumber &&
                      errors?.socialSecurityNumber?.message
                    }
                    InputLabelProps={{
                      shrink: false,
                      style: textInputStyles
                    }}
                    value={socialSecurityNumber}
                    sx={textFieldCustom}
                    size={isMobile ? "small" : "medium"}
                    onChange={(e) => {
                      setSocialSecurityNumber(e.target.value);
                      field.onChange(e.target.value);
                    }}
                  />
                </FormControl>
              )}
            />
            {/* socialSecurityNumber */}
          </div>
          <div className="group-btn">
            <PrevBtn to="/step17" />
            <LoadingButton
              endIcon={<SendIcon />}
              loading={loadingButton}
              loadingPosition="end"
              variant="contained"
              fullWidth
              size="large"
              style={btnSubmitCustom}
              onClick={() => {
                handleSubmit(onSubmit);
              }}
              type="submit"
            >
              <span>next step</span>
            </LoadingButton>
          </div>
        </form>
      </div>
      <Footer desc={false} />
    </div>
  );
};

export default Step18;
