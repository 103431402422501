import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step6.scss";
import Progress from "../../../components/Progress/Progress";

import { PrevBtn } from "../../../components/Button/PrevBtn/PrevBtn";

import { useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const Step6 = () => {
  const options = [
    "0 - 1 year",
    "1 - 3 years",
    "7 - 10 years",
    "3 - 5 years",
    "5 - 7 years",
    "over 10 years"
  ];

  // eslint-disable-next-line no-unused-vars
  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [currentResidenceDuration, setCurrentResidenceDuration] = useState(
    contactRedux?.currentResidenceDuration ?? ""
  );
  const windowWidth = window.innerWidth;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // custom
  const {
    formState: { errors }
  } = useForm();
  const onSubmit = async (option) => {
    const res = await updateContact(dispatch, contactRedux._id, {
      currentResidenceDuration: option
    });

    if (res?.status === 200) {
      setTimeout(() => {
        navigate("/step7");
      }, 300);
    }
  };

  useEffect(() => {
    windowWidth < 768 && window.scrollTo(0, 66);
  });

  return (
    <div className="step-6">
      <Navbar />
      <div className="container">
        <Progress step={6} />
      </div>
      <div className="step-main">
        <div className="step-form">
          <h2 className="title">
            How long have you been living at your current residence?
          </h2>

          <div className="form-control">
            <div className="list-btn-radio">
              {options.map((option, index) => (
                <button
                  key={index}
                  className={`btn-radio ${
                    currentResidenceDuration === option ? "active" : ""
                  }`}
                  onClick={(e) => {
                    setCurrentResidenceDuration(option);
                    onSubmit(option);
                  }}
                >
                  {option}
                </button>
              ))}
            </div>

            {currentResidenceDuration === "" && (
              <span className="error">{errors.currentResidenceDuration}</span>
            )}
          </div>

          <div className="group-btn">
            <PrevBtn to="/step5" />
          </div>
        </div>
      </div>
      <Footer desc={false} />
    </div>
  );
};

export default Step6;
