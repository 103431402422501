import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step5.scss";
import Progress from "../../../components/Progress/Progress";

import { PrevBtn } from "../../../components/Button/PrevBtn/PrevBtn";
import SendIcon from "@mui/icons-material/Send";

import LoadingButton from "@mui/lab/LoadingButton";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  TextField
} from "@mui/material";
import {
  ListBoxCustom,
  btnSubmitCustom,
  formControlCustom,
  labelCustom,
  textFieldCustom,
  textInputStyles
} from "./style";

import axios from "axios";
import { trimWhiteSpaceObjValue } from "../../../utils/functions";

const Step5 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const [loadingButton, setLoadingButton] = useState(false);
  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [openStreetAddressOptions, setOpenStreetAddressOptions] =
    useState(false);

  const [loadingZip, setLoadingZip] = useState(false);
  const [zipCode, setZipCode] = useState(contactRedux?.zipCode ?? "");
  const [city, setCity] = useState(contactRedux?.city ?? "");
  const [state, setState] = useState(contactRedux?.state ?? "");
  const [streetAddress, setStreetAddress] = useState(
    contactRedux?.streetAddress ?? ""
  );
  const [addressOptions, setAddressOptions] = useState([]);
  const [typingTimeout, setTypingTimeout] = useState(0);

  // CHECK ZIP
  const checkZipCode = async (zip) => {
    setLoadingZip(!loadingZip);

    const res = await axios.get(
      `https://api.zip-codes.com/ZipCodesAPI.svc/1.0/QuickGetZipCodeDetails/${zip}?key=${process.env.REACT_APP_ZIPCODE_KEY}`
    );

    setTimeout(() => {
      setLoadingZip(false);

      const result = res?.data && res?.data;

      if (result?.ZipCode) {
        setState(result?.State);
        setCity(result?.City);
        clearErrors("zipCode");

        return true;
      } else {
        setState("");
        setCity("");
        setError("zipCode", {
          type: "required",
          message: "Invalid zip code!"
        });
        return "Invalid zip code!";
      }
    }, 500);
  };

  const validateAddress = (addressValue) => {
    const regex = /^[a-zA-Z0-9\s\-/'()&]+$/;
    if (regex.test(addressValue)) {
      clearErrors("streetAddress");
    } else {
      setError("streetAddress", {
        type: "custom",
        message: "The address does not contain special characters!"
      });
    }
    return regex.test(addressValue);
  };

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();
  const onSubmit = async (data) => {
    if (state === "" || city === "") {
      setError("zipCode", {
        type: "required",
        message: "Invalid zip code!"
      });
      return;
    } else {
      clearErrors("zipCode");
    }

    if (!validateAddress(streetAddress)) return;

    setLoadingButton(!loadingButton);

    data.state = state ?? "";
    data.city = city ?? "";

    // DELETE WHITE SPACE FROM OBJECT VALUE
    trimWhiteSpaceObjValue(data);

    const res = await updateContact(dispatch, contactRedux?._id, data);
    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/step6");
      }, 300);
    }
  };

  const callApiCheckAddress = async (value) => {
    if (value.length > 1) {
      const res = await axios.get(
        `https://us-autocomplete-pro.api.smarty.com/lookup?key=${
          process.env.REACT_APP_AUTOCOMPLETE_KEY
        }&search=${value}&selected=&include_only_zip_codes=${
          zipCode ?? ""
        }&max_results=5`
      );
      res?.data?.suggestions && setAddressOptions(res.data.suggestions);
    }
  };

  // Thực hiện gọi API khi ngừng nhập sau 1 giây
  const handleChangeStreetAddress = (value) => {
    // setInputValue(value);

    // Hủy bỏ bất kỳ timeout cũ nào
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Thiết lập timeout mới
    const newTimeout = setTimeout(() => {
      callApiCheckAddress(value);
    }, 300);

    setTypingTimeout(newTimeout);
  };

  // useEffect(() => {
  //   if (zipCode.length > 4 && !contactRedux?.state && !contactRedux?.city)
  //     zipCode.length === 5 && checkZipCode(zipCode);
  // }, [zipCode]);

  return (
    <div className="step-5">
      <Navbar />
      <div className="container">
        <Progress step={5} />
      </div>
      <div className="step-main">
        <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title">Your home address</h2>
          <div className="form-control">
            <FormHelperText sx={labelCustom}>Zipcode</FormHelperText>
            <Controller
              name="zipCode"
              control={control}
              defaultValue={zipCode}
              rules={{
                required: "Zip code is required!",
                minLength: {
                  value: 5,
                  message: "Zip code must be 5 characters long!"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...field}
                  inputRef={(input) => {
                    input?.addEventListener("focus", () => {
                      input?.setAttribute("inputmode", "numeric");
                    });
                    ref(input);
                  }}
                  id="zipCode"
                  sx={{ ...textFieldCustom, marginBottom: "7px" }}
                  // autoComplete="zipCode"
                  variant="outlined"
                  fullWidth
                  type="text"
                  error={!!errors?.zipCode}
                  label={zipCode ? " " : "e.g. 90011"}
                  helperText={errors?.zipCode && errors?.zipCode?.message}
                  inputProps={{
                    maxLength: 5
                  }}
                  InputLabelProps={{
                    shrink: false,
                    style: { ...textInputStyles, color: "#B7B7B7" }
                  }}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, "");
                    field.onChange(value);
                    setZipCode(value);
                    value.length === 5 && checkZipCode(value);
                  }}
                  value={zipCode}
                  size={isMobile ? "small" : "medium"}
                  InputProps={{
                    endAdornment: loadingZip && (
                      <InputAdornment position="end">
                        <CircularProgress
                          size={windowWidth > 768 ? 20 : 15}
                          color="inherit"
                        />
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />

            {city && state && (
              <div className="box-state">
                <LocationOnIcon className="address-icon" sx={{ width: 20 }} />
                <p className="state">
                  {city}, {state}
                </p>
              </div>
            )}

            {/* streetAddress */}
            <Controller
              name="streetAddress"
              control={control}
              defaultValue={streetAddress}
              rules={{
                required: "Street Address is required!"
              }}
              render={({ field }) => (
                <FormControl
                  fullWidth
                  sx={{
                    ...formControlCustom,
                    marginBottom: "15px"
                  }}
                >
                  <FormHelperText sx={labelCustom}>
                    Street address
                  </FormHelperText>
                  <Autocomplete
                    {...field}
                    freeSolo
                    id="streetAddress"
                    name="streetAddress"
                    onOpen={() => setOpenStreetAddressOptions(true)}
                    onClose={() => setOpenStreetAddressOptions(false)}
                    disableClearable
                    value={streetAddress}
                    options={addressOptions.map((option) => option.street_line)}
                    onChange={(e, option) => {
                      setStreetAddress(option);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setStreetAddress(newInputValue);
                      field.onChange(newInputValue);
                    }}
                    ListboxComponent="div"
                    ListboxProps={{ sx: ListBoxCustom }}
                    //PopperComponent={isMobile && "bottom"}
                    noOptionsText={false}
                    renderOption={(props, option, optionsObj) => {
                      return (
                        <MenuItem {...props} key={optionsObj.index}>
                          {option}
                        </MenuItem>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={streetAddress ? "" : "e.g. Your address"}
                        size={isMobile ? "small" : "medium"}
                        sx={textFieldCustom}
                        className={
                          openStreetAddressOptions && addressOptions.length > 0
                            ? "show"
                            : ""
                        }
                        InputLabelProps={{
                          shrink: false,
                          style: { ...textInputStyles, color: "#B7B7B7" }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          type: "search"
                        }}
                        onChange={(e) => {
                          const value = e.target.value;
                          validateAddress(value);
                          handleChangeStreetAddress(value);
                        }}
                        error={!!errors?.streetAddress}
                        helperText={
                          errors?.streetAddress &&
                          errors?.streetAddress?.message
                        }
                      />
                    )}
                  />
                </FormControl>
              )}
            />

            {/* streetAddress */}
            <div className="group-btn">
              <PrevBtn to="/step4" />
              <LoadingButton
                endIcon={<SendIcon />}
                loading={loadingButton}
                loadingPosition="end"
                variant="contained"
                fullWidth
                size="large"
                style={btnSubmitCustom}
                // onClick={() => {
                //   handleSubmit(onSubmit);
                // }}
                type="submit"
              >
                <span>NEXT STEP</span>
              </LoadingButton>
            </div>
          </div>
        </form>
      </div>
      <Footer desc={false} />
    </div>
  );
};

export default Step5;
