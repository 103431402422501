import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step14.scss";
import Progress from "../../../components/Progress/Progress";

import { PrevBtn } from "../../../components/Button/PrevBtn/PrevBtn";

import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";

import { useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const Step14 = () => {
  const options = [
    { label: "Yes", value: "YES" },
    { label: "No", value: "NO" }
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowWidth = window.innerWidth;
  const [loadingButton, setLoadingButton] = useState(false);

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [getDirectDeposit, setGetDirectDeposit] = useState(
    contactRedux?.getDirectDeposit || options[0].value
  );

  // STYLE
  const btnSubmitStyle = {
    marginBottom: windowWidth > 756 ? "20px" : 0,
    width: "100%",
    height: windowWidth > 756 ? "50px" : "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    borderRadius: "5px",
    background: "#F4C811",

    color: "#000",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: windowWidth > 756 ? "25px" : "16px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    textTransform: "uppercase"
  };

  // custom
  const {
    handleSubmit,
    formState: { errors }
  } = useForm();
  const onSubmit = async () => {
    setLoadingButton(!loadingButton);

    const res = await updateContact(dispatch, contactRedux._id, {
      getDirectDeposit: getDirectDeposit
    });

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/step15");
      }, 300);
    }
  };

  useEffect(() => {
    windowWidth < 768 && window.scrollTo(0, 66);
  });

  return (
    <div className="step-14">
      <Navbar />
      <div className="container">
        <Progress step={14} />
      </div>
      <div className="step-main">
        <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title">Do you get direct deposit ?</h2>

          <div className="form-control">
            <div className="list-btn-radio">
              {options.map((option, index) => (
                <button
                  key={index}
                  className={`btn-radio ${
                    getDirectDeposit === option.value ? "active" : ""
                  }`}
                  onClick={() => setGetDirectDeposit(option.value)}
                >
                  {option.label}
                </button>
              ))}
            </div>

            {errors.phone && (
              <span className="error">{errors.phone.message}</span>
            )}
          </div>

          <div className="group-btn">
            <PrevBtn to="/step13" />
            <LoadingButton
              endIcon={<SendIcon />}
              loading={loadingButton}
              loadingPosition="end"
              variant="contained"
              fullWidth
              size="large"
              style={btnSubmitStyle}
              onClick={() => {
                handleSubmit(onSubmit);
              }}
              type="submit"
            >
              <span>next step</span>
            </LoadingButton>
          </div>
        </form>
      </div>
      <Footer desc={false} />
    </div>
  );
};

export default Step14;
