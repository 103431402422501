import React, { useRef, useEffect } from "react";
import "./MarketingPartners.scss";
import close from "../../../assets/images/close1.png";

export const MarketingPartners = ({
  opentMarketingPartners,
  setOpentMarketingPartners
}) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setOpentMarketingPartners(false);
      }
    };

    if (opentMarketingPartners) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [opentMarketingPartners, setOpentMarketingPartners]);

  return (
    <div
      className={`marketing-partners ${opentMarketingPartners ? "open" : ""}`}
    >
      <div className="box-popup">
        <div className="container">
          <div className="content-popup" ref={popupRef}>
            <h2 className="title">Marketing Partners</h2>
            <p className="text">
              Affiliate ROI, AHF, Avenue Link, Beyond Finance dba Accredited
              Debt Relief, Bulldog, Bulldog Media, Check Draw, ClearOne
              Advantage, Debt Choice, Debt Help Center, Debt Relief LLC, Diablo
              Media, Discover Nimbus Media, DOT818, Dynu In Media, EPCVIP, Fast,
              Funds, Getfreebiestoday, Gets Loan Now, Grant For Home Buyer, Home
              Improvement LLC, Inbounds, IT Media, International Rate, Lead
              Economy, Lead Network, LeadsGate, LeadsMarket, Leap Theory,
              MaxCash, Onpoint , Round Sky, Search ROI, LLC, Section8.com, Stop
              Go, Stop Go Networks, Super Money, Teapot Media, The Credit Pros,
              The Lead Group, True Loan Here, Trusted Insurance, Turbo Debt,
              Zappian Media, Zero Parallel.
            </p>
          </div>
          <button
            className="close"
            onClick={() => setOpentMarketingPartners(!opentMarketingPartners)}
          >
            <img src={close} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};
