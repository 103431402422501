import axios from "axios";

const BASE_URL = "https://api.seekerloans.com/api";
// const BASE_URL = "http://localhost:5100/api/";
// const TOKEN =
// 	localStorage.getItem("persist:root") &&
// 	JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
// 		.currentUser.accessToken;

const TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzYjNhNWNkN2JhMmVmODU0ZmEzMjE4ZiIsImlzQWRtaW4iOnRydWUsImlhdCI6MTY3NjQ2NjQ5MywiZXhwIjoxNjc2NzI1NjkzfQ.sjsPVkrL-gRbhHglFxyhAnS5eubM7rq2xWqQruJS2Ec";

export const publicRequest = axios.create({
  baseURL: BASE_URL
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  header: { token: `Bearer ${TOKEN}` }
});
