import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step15.scss";
import Progress from "../../../components/Progress/Progress";

import { PrevBtn } from "../../../components/Button/PrevBtn/PrevBtn";

import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const Step15 = () => {
  const currentBankDurationOptions = [
    "1 - 3 month",
    "3 - 6 months",
    "7 - 12 months",
    "1 - 2 year",
    "More than 2 years"
  ];
  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [currentBankDuration, setCurrentBankDuration] = useState(
    contactRedux?.currentBankDuration ?? ""
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowWidth = window.innerWidth;

  const onSubmit = async (e, currentBankDuration) => {
    e.preventDefault();

    const res = await updateContact(dispatch, contactRedux._id, {
      currentBankDuration: currentBankDuration
    });

    if (res?.status === 200) {
      setTimeout(() => {
        navigate("/step16");
      }, 300);
    }
  };

  useEffect(() => {
    windowWidth < 768 && window.scrollTo(0, 66);
  });

  return (
    <div className="step-15">
      <Navbar />
      <div className="container">
        <Progress step={15} />
      </div>
      <div
        className="step-main"
        // style={{ height: `calc(100vh - ${containerHeight}px)` }}
      >
        <form className="step-form">
          <h2 className="title">
            How long have you been at your current bank?
          </h2>

          <div className="form-control">
            <div className="list-btn-radio">
              {currentBankDurationOptions.map((option, index) => (
                <button
                  key={index}
                  className={`btn-radio ${
                    currentBankDuration === option ? "active" : ""
                  }`}
                  onClick={(e) => {
                    setCurrentBankDuration(option);
                    onSubmit(e, option);
                  }}
                >
                  {option}
                </button>
              ))}
            </div>
          </div>

          <div className="group-btn">
            <PrevBtn className="btn-prev" to="/step14" />
          </div>
        </form>
      </div>
      <Footer desc={false} />
    </div>
  );
};

export default Step15;
