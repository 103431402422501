import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";

import Footer from "../../../components/Footer/Footer";

import "./Step3.scss";
import Progress from "../../../components/Progress/Progress";

import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";

import { PrevBtn } from "../../../components/Button/PrevBtn/PrevBtn";

import { Controller, useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { FormHelperText, TextField } from "@mui/material";
import InputMask from "react-input-mask";
// CUSTOM STYLE
import { MarketingPartners } from "../../../components/popups/MarketingPartners/MarketingPartners";
import { btnSubmitCustom, labelCustom, textFieldCustom } from "./style";

import { publicRequest } from "../../../requestMethods";

const Step3 = () => {
  const navigate = useNavigate();
  const dispath = useDispatch();

  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const [loadingButton, setLoadingButton] = useState(false);
  const [opentMarketingPartners, setOpentMarketingPartners] = useState(false);

  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [phone, setPhone] = useState(contactRedux?.phone ?? "");

  // CALL API CHECK PHONE NUMBER
  const checkPhoneNumberApi = async (phoneNumber) => {
    try {
      const response = await publicRequest.get(
        `/validate/phone/${phoneNumber}`
      );
      return response.data;
    } catch (error) {
      console.error("Error checking phone number:", error);
      throw error;
    }
  };

  // SUBMIT FUNC
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm();
  const onSubmit = async (data) => {
    try {
      setLoadingButton(!loadingButton);

      // check phone number before submit form
      if (data?.phone) {
        const phoneFormatted = data?.phone.replace(/[-()]/g, "");
        const isValidPhoneNumber = await checkPhoneNumberApi(phoneFormatted);

        if (isValidPhoneNumber?.isValid && isValidPhoneNumber?.country === "US") {
          const res = await updateContact(dispath, contactRedux?._id, data);

          if (res?.status === 200) {
            setTimeout(() => {
              setLoadingButton(false);
              navigate("/step4");
            }, 300);
          }
        } else {
          setLoadingButton(false);
          setError("phone", {
            type: "error",
            message: "Phone number is invalid!"
          });
        }
      }
    } catch (error) {
      // Xử lý lỗi nếu có
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      {opentMarketingPartners && (
        <MarketingPartners
          opentMarketingPartners={opentMarketingPartners}
          setOpentMarketingPartners={setOpentMarketingPartners}
        />
      )}

      <div className="step-3">
        <Navbar />
        <div className="container">
          <Progress step={3} />
        </div>
        <div className="step-main">
          <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
            <h2 className="title">
              Hi {contactRedux?.firstName},<br /> what is your phone number?
            </h2>
            <h5>
              Lenders can call you to discuss your terms through your provided
              phone number
            </h5>
            <div className="form-control">
              <FormHelperText sx={labelCustom}>
                Your phone number
              </FormHelperText>
              <Controller
                name="phone"
                control={control}
                defaultValue={phone}
                rules={{
                  required: "Phone number is required!",
                  pattern: {
                    value: /\((?!0|1)\d{3}\)-\d{3}-\d{4}/,
                    message: "The phone number is not correct!"
                  }
                }}
                render={({ field: { ref, ...field } }) => (
                  <InputMask {...field} mask="(999)-999-9999" maskChar="_">
                    {() => (
                      <TextField
                        {...field}
                        inputRef={(input) => {
                          input?.addEventListener("focus", () => {
                            input?.setAttribute("inputmode", "numeric");
                          });
                          ref(input);
                        }}
                        fullWidth
                        sx={{ ...textFieldCustom, marginBottom: "13px" }}
                        size={isMobile ? "small" : "medium"}
                        error={!!errors?.phone}
                        helperText={errors?.phone && errors?.phone?.message}
                        placeholder="e.g. (123)-123-1234 "
                        InputLabelProps={{ shrink: false }}
                        onInput={(e) => setPhone(e.target.value)}
                        // autoComplete="phone"
                      />
                    )}
                  </InputMask>
                )}
              />
            </div>
            <p className="text">
              By clicking the <b>"Next Step"</b> button, I verify that this is
              my phone number and I hereby provide my express consent to be
              contacted at the telephone number by Search ROI, LLC, Cash By
              Phone, LLC, and{" "}
              <Link
                onClick={() =>
                  setOpentMarketingPartners(!opentMarketingPartners)
                }
              >
                Marketing Partners
              </Link>
              , subsidiaries or agents in connection with my loan request and
              for other marketing purposes, including contact through automatic
              dialing systems, artificial or pre-recorded voice messaging, or
              text message I understand I am not required to provide my consent
              as a condition of purchasing any products or services. I further
              understand I can revoke my consent at any time and my provider may
              charge me for these messages. For text messaging campaigns, text
              STOP to stop and HELP for help. Message frequency varies. Msg &
              data rates may apply.
            </p>
            <div className="group-btn">
              <PrevBtn to="/step2" />
              <LoadingButton
                endIcon={<SendIcon />}
                loading={loadingButton}
                loadingPosition="end"
                variant="contained"
                fullWidth
                size="large"
                style={btnSubmitCustom}
                onClick={() => {
                  handleSubmit(onSubmit);
                }}
                type="submit"
              >
                <span>next step</span>
              </LoadingButton>
            </div>
          </form>
        </div>
        <Footer desc={false} />
      </div>
    </>
  );
};

export default Step3;
