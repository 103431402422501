import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step16.scss";
import { issuingStateOptions } from "./options";
import Progress from "../../../components/Progress/Progress";
import { PrevBtn } from "../../../components/Button/PrevBtn/PrevBtn";
import SendIcon from "@mui/icons-material/Send";

import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { useForm, Controller } from "react-hook-form";

import { Autocomplete, FormHelperText, TextField } from "@mui/material";
import { FormControl } from "@mui/material";

// CUSTOM STYLE
import LoadingButton from "@mui/lab/LoadingButton";

import {
  ListBoxCustom,
  btnSubmitCustom,
  formControlCustom,
  labelCustom,
  textFieldCustom,
  textInputStyles
} from "./style";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { trimWhiteSpaceObjValue } from "../../../utils/functions";

const Step16 = () => {
  const navigate = useNavigate();
  const dispath = useDispatch();

  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;

  const [loadingButton, setLoadingButton] = useState(false);
  const [openIssuingState, setOpenIssuingState] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const contactRedux = useSelector((state) => state?.contact?.contact);

  const [licenseID, setLicenseID] = useState(contactRedux?.licenseID ?? "");
  const [issuingState, setIssuingState] = useState(
    issuingStateOptions.filter(
      (option) =>
        option.value === (contactRedux?.state ?? contactRedux?.issuingState)
    )[0]?.label ?? issuingStateOptions[0].label
  );

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    setLoadingButton(!loadingButton);

    data.issuingState = issuingStateOptions.filter(
      (option) => option.label === issuingState
    )[0]?.value;

    // DELETE WHITE SPACE FROM OBJECT VALUE
    trimWhiteSpaceObjValue(data);

    const res = await updateContact(dispath, contactRedux?._id, data);

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/step17");
      }, 300);
    }
  };

  return (
    <div className="step-16">
      <Navbar />
      <div className="container">
        <Progress step={16} />
      </div>
      <div className="step-main">
        <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title">Driver's License state</h2>
          <h5>
            Your driver’s license number is used to verify your identity and
            prevent fraud.
          </h5>
          <div className="form-control">
            {/* licenseID */}
            <Controller
              name="licenseID"
              control={control}
              defaultValue={licenseID}
              rules={{
                required: "LicenseID is required!",
                minLength: {
                  value: 2,
                  message: "Please enter at least 2 characters"
                },
                maxLength: {
                  value: 25,
                  message: "LicenseID must be 10 characters long"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <FormControl
                  fullWidth
                  sx={{ ...formControlCustom, marginBottom: "7px" }}
                >
                  <FormHelperText sx={labelCustom}>
                    Your license ID
                  </FormHelperText>
                  <TextField
                    {...field}
                    inputRef={ref}
                    id="licenseID"
                    size={isMobile ? "small" : "medium"}
                    sx={textFieldCustom}
                    InputLabelProps={{
                      shrink: false,
                      style: { ...textInputStyles, color: "#B7B7B7" }
                    }}
                    variant="outlined"
                    fullWidth
                    type="text"
                    error={!!errors?.licenseID}
                    label={licenseID ? " " : "1 to 19 characters"}
                    helperText={errors?.licenseID && errors?.licenseID?.message}
                    inputProps={{
                      maxLength: 25,
                      onInput: (e) => {
                        const value = e.target.value;
                        // const value = e.target.value.replace(/[^0-9]/g, "");
                        setLicenseID(value);
                      }
                    }}
                    value={licenseID}
                  />
                </FormControl>
              )}
            />
            {/* licenseID */}
            {/* issuingState */}
            <Controller
              name="issuingState"
              control={control}
              defaultValue={issuingState}
              rules={{
                required: "FirstName is required!"
              }}
              render={({ field: { ref, ...field } }) => (
                <FormControl
                  fullWidth
                  sx={{
                    ...formControlCustom,
                    marginBottom: "15px"
                  }}
                >
                  <FormHelperText sx={labelCustom}>
                    Issuing state
                  </FormHelperText>
                  <Autocomplete
                    {...field}
                    freeSolo
                    id="issuingState"
                    name="issuingState"
                    onOpen={() => setOpenIssuingState(true)}
                    onClose={() => setOpenIssuingState(false)}
                    disableClearable
                    options={issuingStateOptions.map((option) => option)}
                    onChange={(e, option) => {
                      setIssuingState(option.label);
                    }}
                    onInputChange={(event, newInputValue) => {
                      field.onChange(newInputValue);
                      setIssuingState(newInputValue);
                    }}
                    ListboxComponent="div"
                    ListboxProps={{ sx: ListBoxCustom }}
                    //PopperComponent={isMobile && "bottom"}
                    noOptionsText={false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={ref}
                        size={isMobile ? "small" : "medium"}
                        sx={textFieldCustom}
                        className={
                          openIssuingState && issuingStateOptions.length > 0
                            ? "show"
                            : ""
                        }
                        InputLabelProps={{
                          shrink: false,
                          style: textInputStyles
                        }}
                        error={!!errors?.issuingState}
                        helperText={
                          errors?.issuingState && errors?.issuingState?.message
                        }
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            // <KeyboardArrowDownIcon
                            //   sx={{
                            //     transition: "all 0.5s ease",
                            //     transform: openIssuingState
                            //       ? "rotate(180deg)"
                            //       : "rotate(0deg)"
                            //   }}
                            // />
                            <ArrowDropDownIcon
                              sx={{
                                transition: "all 0.5s ease",
                                transform: openIssuingState
                                  ? "rotate(180deg)"
                                  : "rotate(0deg)"
                              }}
                            />
                          )
                        }}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
            {/* issuingState */}
          </div>
          <div className="group-btn">
            <PrevBtn to="/step15" />
            <LoadingButton
              endIcon={<SendIcon />}
              loading={loadingButton}
              loadingPosition="end"
              variant="contained"
              fullWidth
              size="large"
              style={btnSubmitCustom}
              onClick={() => {
                handleSubmit(onSubmit);
              }}
              type="submit"
            >
              <span>next step</span>
            </LoadingButton>
          </div>
        </form>
      </div>

      <Footer desc={false} />
    </div>
  );
};

export default Step16;
