import React, { useEffect, useState } from "react";
import "./Progress.scss";

const Progress = ({ step, myRef }) => {
  const prevValue = (100 / 20) * (step - 1);
  const currentValue = (100 / 20) * step;

  const [progressValue, setProgressValue] = useState(prevValue);

  useEffect(() => {
    const startTime = Date.now();
    const duration = 1000; // Thời gian animation (1 giây)
    // const endTime = startTime + duration;

    const updateProgress = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;

      if (elapsedTime < duration) {
        const percentage = (elapsedTime / duration) * 100;
        const interpolatedValue =
          prevValue + (currentValue - prevValue) * (percentage / 100);
        setProgressValue(interpolatedValue);
        requestAnimationFrame(updateProgress);
      } else {
        setProgressValue(currentValue);
      }
    };

    requestAnimationFrame(updateProgress);
  }, [prevValue, currentValue]);

  return (
    <div className="progress" ref={myRef}>
      <div id="progress-bar">
        <div
          id="bar"
          style={{
            width: step === 1 ? `${"7%"}` : progressValue + "%"
          }}
        >
          <span id="progress-number">{Math.round(progressValue)}%</span>
        </div>
      </div>
    </div>
  );
};

export default Progress;
