import { Link, useLocation } from "react-router-dom";

import logo from "../../assets/images/ft-logo.png";
import ssl from "../../assets/images/ssl.png";
import secure from "../../assets/images/secure.png";
import { Link as Scroll } from "react-scroll";

import "./Footer.scss";

const Footer = ({ desc = true }) => {
  const location = useLocation();
  const menuURL = location.pathname.split("/")[1];

  return (
    <footer
      className="footer"
      id="footer"
      style={{
        background:
          menuURL === ""
            ? "#3f3f3f"
            : "linear-gradient(90deg, #3c3c3c 46.45%, #a6a7a8 100%)"
      }}
    >
      <div className="container">
        <div className="footer-top">
          <div className="logo-and-certificate">
            <div className="logo">
              {menuURL === "" ? (
                <Scroll
                  className="menu-item"
                  to="navbar"
                  spy={true}
                  smooth={true}
                  duration={800}
                  offset={-120}
                  style={{
                    cursor: "pointer"
                  }}
                >
                  <img src={logo} alt="" />
                </Scroll>
              ) : (
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              )}
            </div>
            <div className="certificate">
              <div className="ssl">
                <img src={ssl} alt="" />
              </div>
              <div className="secure">
                <img src={secure} alt="" />
              </div>
            </div>
          </div>
          <div className="menu">
            {menuURL === "" ? (
              <Scroll
                className="item"
                to="navbar"
                spy={true}
                smooth={true}
                duration={800}
                offset={-120}
              >
                Home
              </Scroll>
            ) : (
              <Link to="/" className="item">
                Home
              </Link>
            )}
            <Link to="/privacy" className="item">
              Privacy Policy
            </Link>
            <Link to="/disclaimer" className="item">
              Disclaimer
            </Link>
            <Link to="/contact-us" className="item">
              Contact us
            </Link>
            <Link to="/terms-of-use" className="item">
              Terms of Use
            </Link>

            <Link to="/do-not-sell-information" className="item">
              Do not sell my information
            </Link>
            <Link to="/unsubscribe" className="item">
              Unsubscribe
            </Link>
          </div>
        </div>

        <div className="footer-bottom">
          {desc && (
            <p className="desc">
              The operator of this website is not a lender, or a representative,
              broker, agent of any lenders and we do not make any loan or credit
              decisions. This website does not constitute an offer or
              solicitation to lend. The role of this website is to submit the
              information you provide to a lender who has the whole rights of
              reviewing and approving for a loan. By submitting your
              information, you agree to allow participating lenders to verify
              your information and check your credit. Not all lenders can
              provide the maximum amount advertised. The time of cash transfer
              may vary between lenders and may depend on your individual
              financial institution. In some circumstances faxing may be
              required. Not all lenders from our system operate in all US
              states, and the states serviced by this website may change from
              time to time and without notice. If you have any questions about
              your loan, please contact your lеndеr directly. Short term cash
              loans are meant to address immediate cash needs and are not a
              long-term solution for financial problems. Residents of some
              states may not be eligible for a short term cash loan based upon
              lender requirements.
            </p>
          )}
          <p className="copyright">
            © 2023 Seekerloans.com All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
