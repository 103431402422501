import React, { useRef, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

import "./Step11.scss";
import Progress from "../../../components/Progress/Progress";

import LoadingButton from "@mui/lab/LoadingButton";

import SendIcon from "@mui/icons-material/Send";
import { PrevBtn } from "../../../components/Button/PrevBtn/PrevBtn";

import { Controller, useForm } from "react-hook-form";
import { updateContact } from "../../../redux/apiCalls";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField
} from "@mui/material";

import {
  ListBoxCustom,
  btnSubmitCustom,
  formControlCustom,
  labelCustom,
  textFieldCustom,
  textInputStyles
} from "./style";
// CUSTOM STYLE
import {
  searchEmployerNameApi,
  searchJobTitleApi
} from "../../../utils/callApi";
import ReactInputMask from "react-input-mask";
import { trimWhiteSpaceObjValue } from "../../../utils/functions";

const Step11 = () => {
  const navigate = useNavigate();
  const dispath = useDispatch();
  const [loadingButton, setLoadingButton] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const contactRedux = useSelector((state) => state?.contact?.contact);
  const [jobTitle, setJobTitle] = useState(contactRedux?.jobTitle ?? "");
  const [employerName, setEmployerName] = useState(
    contactRedux?.employerName ?? ""
  );
  const [employerPhone, setEmployerPhone] = useState(
    contactRedux?.employerPhone ?? ""
  );

  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [openJobTitle, setOpenJobTitle] = useState(false);
  const [employerNameOptions, setEmployerNameOptions] = useState([]);
  const [openEmployerName, setOpenEmployerName] = useState(false);

  const windowWidth = window.innerWidth;
  const isMobile = windowWidth < 768;
  const timeoutRef = useRef(null);

  // CALL API SEARCH JOB TITLE
  const handleSearchJobTitle = async (searchValue) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const isValidInput = searchValue.length > 0 && searchValue.length < 50;
    // Wait for 1 second before making the API call
    timeoutRef.current = setTimeout(async () => {
      if (isValidInput) {
        const res = await searchJobTitleApi(searchValue);
        setJobTitleOptions(res || []);
      }
    }, 300);
  };

  // CALL API SEARCH EMPLOYER NAME
  const handleSearchEmployerName = async (searchValue) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const isValidInput = searchValue.length > 0 && searchValue.length < 50;
    timeoutRef.current = setTimeout(async () => {
      if (isValidInput) {
        const res = await searchEmployerNameApi(searchValue);
        setEmployerNameOptions(res || []);
      }
    }, 300);
  };

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const onSubmit = async (data) => {
    setLoadingButton(!loadingButton);

    // DELETE WHITE SPACE FROM OBJECT VALUE
    trimWhiteSpaceObjValue(data);

    const res = await updateContact(dispath, contactRedux?._id, data);

    if (res?.status === 200) {
      setTimeout(() => {
        setLoadingButton(false);
        navigate("/step12");
      }, 300);
    }
  };

  return (
    <div className="step-11">
      <Navbar />
      <div className="container">
        <Progress step={11} />
      </div>
      <div className="step-main">
        <form className="step-form" onSubmit={handleSubmit(onSubmit)}>
          <h2 className="title">Let share more about your job</h2>
          <div className="form-control">
            {/* jobTitle */}
            <Controller
              name="jobTitle"
              control={control}
              defaultValue={jobTitle}
              rules={{
                required: "Email is required!"
              }}
              render={({ field: { ref, ...field } }) => (
                <FormControl
                  fullWidth
                  sx={{
                    ...formControlCustom,
                    marginBottom: "7px"
                  }}
                >
                  <FormHelperText sx={labelCustom}>
                    Your job title
                  </FormHelperText>
                  <Autocomplete
                    {...field}
                    freeSolo
                    id="jobTitle"
                    name="jobTitle"
                    onOpen={() => setOpenJobTitle(true)}
                    onClose={() => setOpenJobTitle(false)}
                    disableClearable
                    value={jobTitle}
                    options={jobTitleOptions}
                    onChange={(e, option) => {
                      setJobTitle(option);
                    }}
                    onInputChange={(event, newInputValue) => {
                      handleSearchJobTitle(newInputValue);
                      field.onChange(newInputValue);
                    }}
                    ListboxComponent="div"
                    ListboxProps={{ sx: ListBoxCustom }}
                    //PopperComponent={isMobile && "bottom"}
                    noOptionsText={false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputRef={ref}
                        label={jobTitle ? "" : "e.g. Sales Associate"}
                        size={isMobile ? "small" : "medium"}
                        sx={textFieldCustom}
                        className={
                          openJobTitle && jobTitleOptions.length > 0
                            ? "show"
                            : ""
                        }
                        InputLabelProps={{
                          shrink: false,
                          style: { ...textInputStyles, color: "#B7B7B7" }
                        }}
                        value={jobTitle}
                        error={!!errors?.jobTitle}
                        helperText={
                          errors?.jobTitle && errors?.jobTitle?.message
                        }
                        onChange={(e) => setJobTitle(e.target.value)}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
            {/* jobTitle */}
            {/* employerName */}
            <Controller
              name="employerName"
              control={control}
              defaultValue={employerName}
              rules={{
                required: "FirstName is required!"
              }}
              render={({ field: { ref, ...field } }) => (
                <FormControl
                  fullWidth
                  sx={{
                    ...formControlCustom,
                    marginBottom: "7px"
                  }}
                >
                  <FormHelperText sx={labelCustom}>
                    Your employer name
                  </FormHelperText>
                  <Autocomplete
                    {...field}
                    freeSolo
                    id="employerName"
                    name="employerName"
                    // autoComplete="off"
                    onOpen={() => setOpenEmployerName(true)}
                    onClose={() => setOpenEmployerName(false)}
                    disableClearable
                    value={employerName}
                    options={employerNameOptions}
                    onChange={(e, option) => {
                      setEmployerName(option);
                    }}
                    onInputChange={(event, newInputValue) => {
                      handleSearchEmployerName(newInputValue);
                      field.onChange(newInputValue);
                    }}
                    ListboxComponent="div"
                    ListboxProps={{ sx: ListBoxCustom }}
                    //PopperComponent={isMobile && "bottom"}
                    noOptionsText={false}
                    renderInput={(params) => (
                      <TextField
                        inputRef={ref}
                        {...params}
                        label={employerName ? " " : "e.g. McDonald"}
                        size={isMobile ? "small" : "medium"}
                        sx={textFieldCustom}
                        className={
                          openEmployerName && employerNameOptions.length > 0
                            ? "show"
                            : ""
                        }
                        InputLabelProps={{
                          shrink: false,
                          style: { ...textInputStyles, color: "#B7B7B7" }
                        }}
                        value={employerName}
                        error={!!errors?.employerName}
                        helperText={
                          errors?.employerName && errors?.employerName?.message
                        }
                        onChange={(e) => setEmployerName(e.target.value)}
                      />
                    )}
                  />
                </FormControl>
              )}
            />
            {/* employerName */}
            {/* employerPhone */}
            <FormHelperText sx={labelCustom}>
              Work Phone Number{" "}
              <span
                style={{
                  color: "#606060",
                  fontFamily: "Poppins",
                  fontSize: isMobile ? "10px" : "12px",
                  fontStyle: "italic",
                  fontWeight: 300,
                  lineHeight: "normal"
                }}
              >
                (We never contact your employer)
              </span>
            </FormHelperText>
            <Controller
              name="employerPhone"
              control={control}
              defaultValue={employerPhone}
              rules={{
                required: "Phone number is required!",
                pattern: {
                  value: /\((?!0|1)\d{3}\)-\d{3}-\d{4}/,
                  message: "The employerPhone number is not correct!"
                }
              }}
              render={({ field: { ref, ...field } }) => (
                <FormControl
                  fullWidth
                  sx={{
                    ...formControlCustom,
                    marginBottom: "15px"
                  }}
                >
                  <ReactInputMask {...field} mask="(999)-999-9999" maskChar="_">
                    {() => (
                      <TextField
                        {...field}
                        inputRef={(input) => {
                          input?.addEventListener("focus", () => {
                            input?.setAttribute("inputmode", "numeric");
                          });
                          ref(input);
                        }}
                        fullWidth
                        sx={textFieldCustom}
                        size={isMobile ? "small" : "medium"}
                        error={!!errors?.employerPhone}
                        helperText={
                          errors?.employerPhone &&
                          errors?.employerPhone?.message
                        }
                        placeholder="e.g. (123)-123-1234 "
                        InputLabelProps={{ shrink: false }}
                        onInput={(e) => setEmployerPhone(e.target.value)}
                      />
                    )}
                  </ReactInputMask>
                </FormControl>
              )}
            />
            {/* employerPhone */}
          </div>
          <div className="group-btn">
            <PrevBtn to="/step10" />
            <LoadingButton
              endIcon={<SendIcon />}
              loading={loadingButton}
              loadingPosition="end"
              variant="contained"
              fullWidth
              size="large"
              style={btnSubmitCustom}
              onClick={() => {
                handleSubmit(onSubmit);
              }}
              type="submit"
            >
              <span>next step</span>
            </LoadingButton>
          </div>
        </form>
      </div>

      <Footer desc={false} />
    </div>
  );
};

export default Step11;
